/* global fetch */
import FormData from 'form-data'
import { cloneDeep as deepCopy, cloneDeep, invert, isEmpty } from 'lodash'
import slugify from 'slugify'
// nextjs polyfills
// Helper Lib
// cSpell:ignore ngql, carg, Rmllb, GREZW, Zpbml, CFDK, DDTHH, Reallylongname, Withmany, Wordsthat, Cango
import util from 'util'
import packageFile from '../../package.json'
import { ignoreDST, phoneAreaCodes, timeZones } from '../dictionaries/dictionaries'

const { version: appVersion } = packageFile
export { deepCopy, cloneDeep, invert } // { cloneDeep as deepCopy, cloneDeep, invert } from 'lodash'
export const jsonwebtoken = require('jsonwebtoken')
const hasOwnProperty = Object.prototype.hasOwnProperty
export const moment = require('moment')
export const _ = require('lodash')
export const immutableObjUpdate = require('immutability-helper')
const mongoose = require('mongoose')
const { customAlphabet } = require('nanoid')
const { ObjectId } = mongoose.Types
ObjectId.prototype.valueOf = function () {
  return this.toString()
}

export const isNumber = (value) => {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN
  if (value === 0) return true
  if (!value) return false
  if (value === ' ') return false
  return !Number.isNaN(Number(value))
}

export const clientWindow = typeof window !== 'undefined' ? window : undefined

const thisFile = 'src/helpers/helper ' // eslint-disable-line no-unused-vars

/**
 *  Is used to get the slug from displayId.  Removes STA- if exists and returns up to the first '-' (hyphen).
 *
 * @param {*} displayId
 * @returns
 */
export const getSlugFromDisplayId = (displayId) => {
  if (displayId?.substr(0, 4) === 'STA-') displayId = displayId?.substr(4)
  const firstHyphen = displayId?.indexOf('-')
  if (~firstHyphen) {
    return displayId?.substr(0, firstHyphen)
  } else {
    throw new Error(consoleError(thisFile, 'getSlugFromDisplayId:', displayId, 'failed with firstHyphen:', firstHyphen))
  }
}

/**
 *  Mongoose doesn't like to be called with await and 6.0 enforced.  This makes it valid syntax
 *  to only get called once.
 *
 * @param {*} model
 * @param {*} { search, update, options }
 * @returns
 */
export const findOneAndUpdatePromise = (model, { search, update, options }) => {
  // delele _id from the update if it contains becuase it is Immutable field
  // eslint-disable-next-line no-prototype-builtins
  update?.$set?.hasOwnProperty('_id') && delete update.$set._id
  return new Promise((resolve, reject) => {
    model.findOneAndUpdate(search, update, options, (err, doc) => {
      if (err) {
        consoleWarn(`Could not update ${model}. Details: ` + JSON.stringify(err))
        reject(new Error(`Could not update ${model}`))
      }
      resolve(doc)
    })
  })
}

/**
 * @typedef {Object} ConvertedQuestions
 * @property {Array<import('@src/dictionaries/commonInterfaces').Question>} convertedQuestions The given questions with select answerOptions converted.
 * @property {Record<string, any>} convertOperations An array of operations that can be given to MongoDB
 * to bulk convert the answerOptions field.
 */

/**
 * Turn any string delimited answers of a question into an array. If the answers to a
 * question are already an array, this is a no-op. This is a pure function, so it will
 * return operations for the database to do but will not operate on the database itself.
 * @param {Array<import('@src/dictionaries/commonInterfaces').Question>} questions
 * @returns {ConvertedQuestions} A ConvertedQuestions object with the changes.
 */
export function convertAnswerOptions(questions) {
  const answerType = 'Select '
  const delimiter = ';'

  return {
    // Map the entire questions array to a new array with any answerOptions converted.
    convertedQuestions: questions.map((question) => {
      // Any questions we aren't going to touch should at least be empty arrays, not null.
      if (!question.answerOptions) question.answerOptions = []

      // We really only want to mess with certain answer types.
      return question.answerType?.includes(answerType)
        ? {
            // Get all the current keys of the question.
            ...question,

            // For answerOptions, if it's a string, split it into an array.
            answerOptions:
              typeof question.answerOptions === 'string'
                ? question.answerOptions.split(delimiter)
                : // If not, return it as-is, or if null an empty array.
                  question.answerOptions || []
          }
        : // The answer type didn't match so just return the question as-is.
          question
    }),

    // Any questions that require answerOptions to be converted, create an updateOne for it.
    convertOperations: questions
      .filter((question) => typeof question.answerOptions === 'string')
      .map((question) => {
        return {
          [`questions.${question.questionSlug}.answerOptions`]: question.answerOptions.split(delimiter)
        }
      })
      .reduce((acc, curr) => {
        return { ...acc, ...curr }
      }, {})
  }
}

/**
 * Taken from https://stackoverflow.com/questions/4994201/is-object-empty
 * If you only need to handle ECMAScript5 browsers, you can use Object.getOwnPropertyNames instead of the hasOwnProperty loop:
 *  if (Object.getOwnPropertyNames(obj).length) return false
 * @param obj
 * @returns {boolean}
 * @see user.js 2 usages
 */
export function isEmptyObject(obj) {
  // null and undefined are "empty"
  if (obj == null) return true

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false
  if (obj.length === 0) return true

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return true

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (const key in obj) {
    if (hasOwnProperty.call(obj, key)) return false
  }

  return true
}

/**
 * A more modern JavaScript and TypeScript implementation of a simple object to flat property map converter. It's using Object.entries to do a proper for of loop only on owned properties.
 * https://stackoverflow.com/questions/44134212/best-way-to-flatten-js-object-keys-and-values-to-a-single-depth-array/59787588
 *
 * @export
 * @param {*} object
 * @param {string} [keySeparator='.']
 * @returns
 */
export function toFlatPropertyMap(object, keySeparator = '.') {
  const flattenRecursive = (obj, parentProperty, propertyMap = {}) => {
    for (const [key, value] of Object.entries(obj)) {
      const property = parentProperty ? `${parentProperty}${keySeparator}${key}` : key
      if (value && typeof value === 'object') {
        flattenRecursive(value, property, propertyMap)
      } else {
        propertyMap[property] = value
      }
    }
    return propertyMap
  }
  return flattenRecursive(object)
}
/**
 * this funstion take a nested object flatten it and preserving both  keys and values
 */
export function flattenedObject(nestedObject) {
  return _.transform(
    nestedObject,
    (result, value, key) => {
      if (_.isPlainObject(value)) {
        // Recursively flatten nested objects
        const nested = _.transform(value, (nestedResult, nestedValue, nestedKey) => {
          nestedResult[`${key}.${nestedKey}`] = nestedValue
        })
        _.assign(result, nested)
      } else {
        result[key] = value
      }
    },
    {}
  )
}

/**
 * Boolean check for detecting Invalid Date
 *
 * @param {*} date
 * @returns {boolean} `true` if the date is valid, `false` otherwise.
 */
export function isValidDate(date) {
  if (typeof date === 'string') {
    try {
      date = new Date(date)
    } catch (e) {
      return false
    }
  }
  return date instanceof Date && !isNaN(date)
}

/**
 * takes a date as a string and formats it to local settings
 * @param {string} dateToBeLocalized
 * @returns {string} localizedDateString
 */
export function localizeDateFormat(dateToBeLocalized, local = 'en-US', options = {}) {
  return new Date(dateToBeLocalized).toLocaleString(local, options)
}

/**
 * Get parts of a date for de structured string building
 *
 * @param {*} date
 * @returns
 */
export const getDateParts = (dateFrom) => {
  if (!dateFrom) return
  return {
    year: dateFrom.getFullYear(),
    day: dateFrom.getDate(),
    month: dateFrom.getMonth() + 1 // 0 based index
  }
}

/**
 * Get parts of a date in UTC for de structured string building
 *
 * @param {*} date
 * @returns
 */
export const getDatePartsUTC = (dateFrom) => {
  if (!dateFrom) return
  const dateParts = {
    year: dateFrom.getUTCFullYear(),
    year2: dateFrom.getUTCFullYear().toString().substr(2, 2),
    day: dateFrom.getUTCDate(),
    day2: String(dateFrom.getUTCDate()).padStart(2, '0'),
    month: dateFrom.getUTCMonth() + 1, // 0 based index
    month2: String(dateFrom.getUTCMonth() + 1).padStart(2, '0') // 0 based index
  }
  return dateParts
}

/**
 * from: https://stackoverflow.com/questions/15762768/javascript-math-round-to-two-decimal-places
 * This will ALWAYS return a float!
 * @param {*} n
 * @param {*} digits
 */
export function roundTo(n, digits) {
  let negative = false
  if (digits === undefined) {
    digits = 0
  }
  if (n < 0) {
    negative = true
    n = n * -1
  }
  const multiplicator = Math.pow(10, digits)
  n = parseFloat((n * multiplicator).toFixed(11))
  n = parseFloat((Math.round(n) / multiplicator).toFixed(digits))
  if (negative) {
    n = (n * -1).toFixed(digits)
  }
  return parseFloat(n)
}

/**
 * checks to see if the user has access to the location
 * @param {String} location - the string location/permission to see if the user has access to
 * @param {UserFromToken} user - the user object from the token
 * @returns {Boolean}
 */
export function hasAccess(location, user) {
  if (!location) return true
  return user?.permissions?.includes(location) || false
}

/**
 * The roundTo function guarantees to return a float.  This simply calls that one in turn and returns the results as a string.
 * For convenience, call this instead of String(roundTo(n,x)).toFixed(x)
 * @param {*} n
 * @param {*} digits
 */
export function roundToFixed(n, digits) {
  return roundTo(n, digits).toFixed(digits)
}

/**
 *
 *
 *  a11yProps is material-ui helper function found in their docs.
 *  It's just a function for making a dynamic object and using them as props, to reduce code size.
 * https://material-ui.com/components/tabs/
 *
 * https://stackoverflow.com/questions/57305891/how-to-show-contents-inside-a-tab
 * @param {*} index
 * @returns
 */
export const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

/**
 *
 *
 * @param {*} obj
 * @returns
 */
export function isIterable(obj) {
  // checks for null and undefined
  if (obj == null) {
    return false
  }
  return typeof obj[Symbol.iterator] === 'function'
}

/**
 * formats the phone number for display
 * NOTE: this function is for static number strings not for input fields that have variable length
 * @param {String} phone
 * @returns formatted phone number as xxx-xxx-xxxx
 */
export const formatPhoneNumber = (phone) => {
  if (!phone) return
  if (typeof phone !== 'string') return
  let phoneString = phone
  phoneString = phoneString.replace('+1', '') // remove leading +1
  let cleaned = phoneString.replace(/\D/g, '') // remove anything that is not a number
  if (cleaned.length > 10) {
    // return only the last 10 digits so that 1 (xxx) xxx-xxxx only returns xxx-xxx-xxxx
    cleaned = cleaned.substring(cleaned.length - 10)
  }
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/) // match the first 3 digits, the middle 3 digits, and the last 4 digits
  let formattedPhoneString = ''
  if (match) {
    formattedPhoneString = match[1] + '-' + match[2] + '-' + match[3] // format to xxx-xxx-xxxx
  } else {
    formattedPhoneString = phone // if it doesn't match, just return the original phone input
  }
  return formattedPhoneString
}

/**
 * Capitalizes first character of string and returns said string.
 *
 * @param {*} charString
 * @returns
 */
export const capitalizeFirst = (charString) => {
  if (typeof charString !== 'string') return charString
  return charString.charAt(0).toUpperCase() + charString.slice(1)
}

/**
 * Nice ways to stringify output
 * @param {*} obj
 */
export const jsonStr = (obj, limit = undefined) => {
  let returnString = ''

  try {
    returnString = JSON.stringify(obj, null, 4)
  } catch (error) {
    if (error.message.substr(0, 37) === 'Converting circular structure to JSON') {
      returnString = 'circular - inspect:\n'
      returnString += util.inspect(obj)
    } else {
      consoleError('functionHelper.jsonStr error: [', error.message, ']')
      consoleError('functionHelper.jsonStr obj: ', obj)
      returnString = error.message
    }
  }

  if (limit) return returnString.substr(0, limit)
  return returnString
}

/**
 *
 * Provides a wrapper for authentication messages so we can control when / where reveal information
 * and to what depth since this is a security risk.
 *
 * @param {*} fileName
 * @param {*} functionName
 * @param {*} message
 * @returns
 */
export const authError = (fileName, functionName, message) => {
  if (variantToBool(process.env.RI_DEBUG) || variantToBool(process.env.AUTH_ERROR_DEBUG)) {
    return fileName + ' ' + functionName + ' ' + message
  } else {
    return 'You are not authenticated!'
  }
}

/**
 * Catch all function for any variable that must covert to boolean.
 * @param {*} envVariable
 * @returns {Boolean} primitive
 */
export const variantToBool = (envVariable) => {
  if (!envVariable) return false // Not set
  if (typeof envVariable === 'boolean') return envVariable
  // Checked for unset and boolean primitives.  The rest should be strings.
  if (!(typeof envVariable === 'string')) envVariable = jsonStr(envVariable)
  // All strings default to true unless one of the following.
  switch (envVariable.toLowerCase()) {
    case 'squat':
    case 'bubkis':
    case 'bupkis':
    case 'off':
    case 'offline':
    case 'false':
    case 'f':
    case 'no':
    case 'n':
    case 'not':
    case 'nill':
    case 'zero':
    case 'nada':
    case 'negative':
    case 'zilch':
    case 'null':
    case 'empty':
    case 'nowayhosay':
    case 'elvishasleftthebuilding':
    case 'leftthetracks':
    case 'undefined':
    case '0':
    case 0:
    case null:
    case undefined:
    case 'nan': // Result from Number() when Not A Number
    case String(): // Empty
    case 'n/a': // Human reference to "Not Applicable"
      return false
    default:
      return true
  }
}

const consoleReset = '\x1b[0m'
const consoleRed = '\x1b[31m'
const consoleYellow = '\x1b[33m'
const consoleBlue = '\x1b[34m'
const consoleMagenta = '\x1b[35m'

// Good alternate example
// console.log('%c Oh my heavens! ', 'background: #222; color: #bada55')

const consoleFireTest =
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'test' ||
  process.env.ENV === 'development' ||
  process.env.ENV === 'test' ||
  variantToBool(process.env.RI_DEBUG)
/**
 * Wraps normal console.log with if development.
 */
export function consoleDev() {
  const argsArray = Array.prototype.slice.call(arguments)
  // console.log('helper.consoleError argsArray:', argsArray)
  const arrayStringified = []
  argsArray.forEach((element) => arrayStringified.push(jsonStr(element)))
  if (consoleFireTest) {
    console.log(consoleYellow) // eslint-disable-line no-console
    // eslint-disable-next-line no-console
    console.log.apply(this, arguments) // non es6
    // eslint-disable-next-line no-console
    console.log(consoleReset) // reset
  }
  return argsArray.join(' ')
}

/**
 * Wraps normal console.log with if development.
 */
export function consoleInfo() {
  const argsArray = Array.prototype.slice.call(arguments)
  // console.log('helper.consoleError argsArray:', argsArray)
  const arrayStringified = []
  argsArray.forEach((element) => arrayStringified.push(jsonStr(element)))
  // if (consoleFireTest) {
  console.info(consoleBlue)
  console.info.apply(this, arguments) // non es6
  console.info(consoleReset) // reset
  // }
  return argsArray.join(' ')
}

/**
 * Replaces consoleX. Only returns string.  NO PRINTING
 */
export function consoleString() {
  const argsArray = Array.prototype.slice.call(arguments)
  return argsArray.map((arg) => JSON.stringify(arg, null, 2)).join(' ')
}

/**
 * Wraps normal console.warn with if development.
 */
export function consoleWarn() {
  const argsArray = Array.prototype.slice.call(arguments)
  const arrayStringified = []
  argsArray.forEach((element) => arrayStringified.push(jsonStr(element)))
  console.warn(consoleMagenta) // NOT FOR BROWSER
  console.warn.apply(this, arguments)
  console.warn(consoleReset) // reset
  return argsArray.join(' ')
}

/**
 * Wraps normal console.error with if development.
 *
 * @deprecated Use getLogger (@src/logger) instead
 */
export function consoleError() {
  const argsArray = Array.prototype.slice.call(arguments)
  if (!clientWindow) console.error(consoleRed)
  if (process.env.NODE_ENV === 'test') {
    console.error(consoleRed)
    console.error.apply(this, arguments)
    console.error(consoleReset)
  } else {
    console.error.apply(this, arguments)
  }
  if (!clientWindow) console.error(consoleReset) // reset
  return argsArray.join(' ')
}

/**
 * Used to pick a random number from a pool.  Min and max numbers are included in the pool
 *
 * @export
 * @param {*} min
 * @param {*} max
 * @returns
 */
export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min // The maximum is inclusive and the minimum is inclusive
}

/**
 * Extracts html tag content out.  e.g.  <script>
 * It will use the last set if there are more than one in the content.
 *
 * @param {*} content  Any String.
 * @param {*} tag e.g. script  No gt or lt.
 * @param {*} idText  The id of the tag (required)
 * @returns
 */
export const getHtmlTagContentById = (content, tag, idText) => {
  // If no Id, no content
  const idString = `id="${idText}"`
  const indexOfId = content.indexOf(idString)
  // Get id attribute
  if (indexOfId > -1) {
    const openTagString = `<${tag}`
    const closeTagString = `</${tag}>`
    let openTagIndex = content.indexOf(openTagString)
    let nextOpenTagIndex = content.indexOf(openTagString, openTagIndex + 1)
    // Goes to the last one if there are more than one
    while (nextOpenTagIndex > -1 && nextOpenTagIndex < indexOfId) {
      openTagIndex = nextOpenTagIndex
      nextOpenTagIndex = content.indexOf(openTagString, openTagIndex + 1)
    }
    const openTagEndIndex = content.indexOf('>', openTagIndex + 1) + 1
    const closeTagIndex = content.indexOf(closeTagString, openTagEndIndex)
    if (openTagEndIndex > -1 && closeTagIndex > -1) {
      return content.substr(openTagEndIndex, closeTagIndex - openTagEndIndex)
    }
  }
}

/**
 * Either returns the json or throws an error with the original body text in it. (e.g. Server error message.)
 *
 * @param {*} text
 */
export const getJsonWithErrorHandling = (text, { throwError = true } = {}) => {
  try {
    const parsedBody = JSON.parse(text)
    return parsedBody
  } catch (error) {
    if (throwError) {
      throw new Error(consoleError('\ngetJsonWithErrorHandling JSON.parse error:', error.message, ' \nbodyText:', text))
    }

    return { error: error.message, text }
  }
}

/**
 * Validates the input string for special characters, 75 char limit and consecutive spaces.
 *
 * @param {string} str - The input string to be checked.
 * @returns {boolean} - Returns true if the string contains special characters,
 * contains consecutive spaces, is more than 75 chars otherwise false.
 */
export const hasSpecialCharsOrUnacceptable = (str) => {
  const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/
  const consecutiveSpacesRegex = / {2,}/

  // Check if the string is empty, null, undefined or more than 75 chars
  if (str === '' || str === ' ' || str === null || str?.length > 75 || str === undefined) {
    return true
  }

  // Check if the string contains any special characters
  // Check if the string contains consecutive spaces
  if (regex.test(str) || consecutiveSpacesRegex.test(str)) {
    return true
  } else {
    return false
  }
}

/**
 * Returns sanitized file name.  Removes invalid characters.
 *
 * @param {*} fileName
 * @returns {string} sanitizedFileName
 * @throws {Error} If the input fileName or the sanitizedFileName is empty.
 */
export const sanitizeFileName = (fileName) => {
  if (!fileName) {
    consoleError('sanitizeFileName fileName is empty.')
    return ''
  }

  // eslint-disable-next-line no-control-regex
  const forbiddenCharsRegex = /[<>:"/\\|?!*=\x00-\x1F]/g
  const sanitizedFileName = fileName.replace(forbiddenCharsRegex, '')

  if (!sanitizedFileName) {
    consoleError('sanitizeFileName sanitizedFileName is empty.')
  }

  return sanitizedFileName
}

/**
 * Does not error out on invalid string.  Instead returns it.  Like Objects.
 *
 * @param {*} text
 * @returns
 */
export const jsonPar = (text) => {
  try {
    return text && JSON.parse(text)
  } catch (error) {
    consoleError(thisFile, 'jsonPar error:', error.message)
    return text
  }
}

/**
 * Sorts and Object by the passed key or first in keys() array if omitted
 *
 * @param {Object} objectToSort - Object to sort
 * @param {string} [sortKey] - Key to sort by
 * @returns
 */
export const objectSort = (objectToSort, sortKey) => {
  if (!objectToSort) return objectToSort
  if (!sortKey) sortKey = Object.keys(objectToSort)[0]
  const objectToSortCopy = deepCopy(objectToSort)
  try {
    const returnObject = objectToSortCopy.sort((a, b) => {
      if (typeof a[sortKey] === 'string' && typeof b[sortKey] === 'string') {
        const lowerA = a[sortKey].toLowerCase()
        const lowerB = b[sortKey].toLowerCase()
        return lowerA > lowerB ? 1 : -1
      } else {
        return a > b ? 1 : -1
      }
    })
    return returnObject
  } catch (error) {
    consoleError(thisFile, 'objectSort error:', error.message)
    return objectToSort
  }
}

/**
 * Converts questionText to questionSlug with shared logic
 *
 * @param {*} questionText
 * @returns {string}
 */
export const makeSlug = (text) => {
  // consoleDev(thisFile, 'questionToSlug.questionText: ', questionText)
  /*
      slugify('some string', {
        replacement: '-',    // replace spaces with replacement
        remove: null,        // regex to remove characters
        lower: true,         // result in lower case
      })

      Ty likes underscore, limit to 10 words, remove common words like 'the', 'a', 'and', and punctuation
  */
  let slug = text
  slug = slug.replace(/\bthe\b/g, ' ')
  slug = slug.replace(/\ba\b/g, ' ')
  slug = slug.replace(/\band\b/g, ' ')
  // consoleDev(thisFile, 'questionToSlug.slug: ', slug)
  slug = slugify(slug, {
    replacement: '_',
    remove: /[*+~.,()'"!?:@]/g,
    lower: false
  })
  // consoleDev(thisFile, 'questionToSlug.slug: ', slug)
  let count = 0
  let index = 0
  let end = false
  while (count < 10) {
    index = slug.indexOf('_', index + 1)
    if (index === -1) end = true
    ++count
  }
  if (!end) slug = slug.substr(0, index)
  return slug
}

/**
 * Returns a zero padded displayId for sorting
 *
 * @param {string} idString In the form XXX-0, XXX-00, or XXX-000
 * @param {{ separator: string, outLength: number }} options  Contains separator character default '-'
 */
export const zeroPadId = (idString, options = {}) => {
  const { separator = '-', outLength = 4 } = options || {}

  if (!(idString && typeof idString === 'string')) return

  const separatorIndex = idString.indexOf(separator)

  // Use index to get counter
  const counter = !isNaN(Number(idString.substr(separatorIndex + 1))) && Number(idString.substr(separatorIndex + 1))

  // re-write idString with padded counter string and return
  if (counter) return idString.substr(0, separatorIndex + 1).concat(String(counter).padStart(outLength, '0'))
}

// export const baseUrl = process.env.BASE_URL ? process.env.BASE_URL : 'bbTest'
export const baseUrl = process.env.BASE_URL
  ? process.env.BASE_URL
  : process.env.VERCEL_URL
    ? 'https://' + process.env.VERCEL_URL
    : 'http://localhost:3000'
export const baseUrlNoProtocol = baseUrl.substr(baseUrl.indexOf('//') + 2) // everything after first //  (http:// or https://)
export const baseContext =
  baseUrl.includes('pro.caseopp.com') || baseUrl.includes('app.caseopp.com')
    ? 'pro'
    : baseUrl.includes('sta.caseopp.com') || baseUrl.includes('sta.rdevs.com')
      ? 'sta'
      : baseUrl.includes('demo.caseopp.com')
        ? 'demo'
        : 'dev'
export const locationHost =
  process.env.PORT && process.env.RDEVS_IP ? process.env.RDEVS_IP + ':' + process.env.PORT : baseUrl
export const locationProtocol = process.env.PORT && process.env.RDEVS_IP ? 'http:' : String()
export const adminPortalBaseUrl = baseUrl.includes('pro.caseopp.com')
  ? 'https://reciprocityadmin.com'
  : 'https://reciprocityadmin.com' // Putting in helper since we will eventually want to split between staging, production, and local
export const baseApiUrl = process.env.BASE_API_URL ? process.env.BASE_API_URL : baseUrl + '/api'

/**
 * Converts an object of key value pairs to url query string.
 * Guaranteed to return string starting with ? and key/value pairs or empty.
 *
 * @param {Object} parameters
 * @returns
 */
export const buildQueryString = (parameters) => {
  // if (parameters && !isEmptyObject(parameters)) {
  //   return '?' + Object.entries(parameters)
  //     .filter(([, value]) => value !== undefined)
  //     .map(([key]) => key + '=' + parameters[key]).join('&')
  // } else return String()
  if (parameters && !isEmptyObject(parameters)) {
    const returnQueryString = new URLSearchParams()
    for (const [key, value] of Object.entries(parameters)) {
      if (value && typeof value !== 'object') {
        returnQueryString.append(key, value)
      }
    }
    const returnString = returnQueryString.toString()
    if (returnString) {
      return '?' + returnString
    } else {
      return String()
    }
  } else return String()
}

/**
 * Used to add / modify any query parameters without reloading.
 *
 * @param {*} parameters
 * @returns { urlParams, urlParamsBefore } Objects of both before and after query parameters
 */
export const getSetUrlParams = (parameters) => {
  if (clientWindow) {
    const queryString = clientWindow.location.search
    // consoleDev(thisFile, `getSetUrlParams queryString:`, queryString)
    const existingURLParameters = new URLSearchParams(queryString)
    const urlParamsBefore = {}
    for (const [key, value] of existingURLParameters) urlParamsBefore[key] = value
    // consoleDev(thisFile, `getSetUrlParams urlParamsBefore:`, urlParamsBefore)
    const urlParams = Object.assign({}, urlParamsBefore, parameters)
    // consoleDev(thisFile, `getSetUrlParams urlParams:`, urlParams)
    if (!isEmptyObject(urlParams)) {
      const newQueryString = buildQueryString(urlParams)
      if (clientWindow.history.pushState) {
        const newURL = new URL(window.location.href)
        newURL.search = newQueryString
        clientWindow.history.pushState({ path: newURL.href }, '', newURL.href)
      }
    }
    return { urlParams, urlParamsBefore }
  }
  return { urlParams: {}, urlParamsBefore: {} }
}

/**
 * Converts date to midnight for the correct date regardless of timezone
 * @param {Date} date - date to convert
 * @returns Date
 */
export const getUTCDate = (date) => {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
}

/**
 * Converts date to the current UTC date and time
 * @param {Date} date - date to convert
 * @returns Date
 */
export const getUTCDateTime = (date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  )
}

/**
 * converts all keys of an object to lower case (i.e. for more tolerance of variation in API POST JSON bodies)
 * @param {Object} object
 */
export const lowerCaseAllKeys = (object) => {
  const keys = Object.keys(object)
  let n = keys.length
  const lowerCasedKeysObject = {}
  while (n--) {
    const key = keys[n]
    lowerCasedKeysObject[key.toLowerCase()] = object[key]
  }
  return lowerCasedKeysObject
}

/**
 * determines if an opp is a test opp base on whether it is scrambled,
 * the email contains @rdevs.com, or the email contains @caseopp.com
 * @param {*} opp
 * @returns {Boolean} true if any of the above are true, false otherwise
 */
export const isTestOpp = (opp) => {
  // consoleInfo(thisFile, 'isTestOpp opp.email:', opp.email)
  if (opp?.scrambled) return true
  if (opp?.email?.includes('@rdevs.com')) return true
  if (opp?.email?.includes('@caseopp.com')) return true
  return false
}

/**
 * takes a camelCase and returns Camel Case
 * thanks https://stackoverflow.com/questions/4149276/how-to-convert-camelcase-to-camel-case
 * @param {String} camelCaseString
 */
export const deCamelCase = (camelCaseString) => {
  return camelCaseString
    .replace(/([A-Z])/g, ' $1') // insert a space before all caps
    .replace(/^./, (str) => str.toUpperCase()) // uppercase the first character
}

/**
 * Deletes a specific key from an object or array of objects.
 * @param {*} obj - The object or array to scrub.
 * @param {string} keyName - The key to delete.
 * @returns The scrubbed object or array.
 */
const deleteKey = (obj, keyName) => {
  if (!obj || typeof obj !== 'object') return obj
  if (Array.isArray(obj)) {
    return obj.map((item) => deleteKey(item, keyName))
  }
  const newObj = { ...obj }
  delete newObj[keyName]
  Object.keys(newObj).forEach((key) => {
    newObj[key] = deleteKey(newObj[key], keyName)
  })
  return newObj
}

/**
 * Deletes multiple keys from an object or array of objects.
 * @param {Object|Array} obj - The object or array to scrub.
 * @param {Array} keyNames - The keys to delete.
 * @returns The scrubbed object or array.
 */
export const deleteKeys = (obj, keyNames) => {
  keyNames.forEach((keyName) => {
    obj = deleteKey(obj, keyName)
  })
  return obj
}

/**
 * Accepts any type of input and scrubs __typename from objects and arrays of objects.
 * @param {*} input - The input to scrub.
 * @returns The scrubbed input.
 */
export const scrubTypename = (input) => {
  if (!input) return undefined // called without input, return undefined
  const clonedInput = cloneDeep(input) // clone whatever we got
  // Recursive function to scrub __typename
  const scrub = (data) => {
    if (Array.isArray(data)) {
      return data.map((item) => scrub(item))
    } else if (typeof data === 'object' && data !== null) {
      if (isEmpty(data)) return data // return the object even if empty
      const cleanedData = { ...data }
      delete cleanedData.__typename
      Object.keys(cleanedData).forEach((key) => {
        cleanedData[key] = scrub(cleanedData[key]) // recursively scrub nested objects
      })
      return cleanedData
    }
    return data // return data if it's not an array or object
  }
  return scrub(clonedInput)
}

/**
 * moment-less formatting from raw timestamp or string of raw timestamp, to string
 * @param {Date or String} timestamp
 * @returns
 */
export const timestampToLocaleString = (timestamp, dateOnly) => {
  // eslint-disable-line no-unused-vars
  if (typeof timestamp === 'number') {
    // as with a raw timestamp like 1951-03-24T00:00:00.000+00:00
    // DB - timestamp- 2021-05-05T17:13:31.464+00:00
    // 1648480576663 - current timestamp from cl of timestamp
    return new Date(timestamp).toLocaleString('en-US')
  } else if (typeof timestamp !== 'string') {
    timestamp = String(timestamp)
  }

  return new Date(timestamp).toLocaleString('en-US')
}

/**
 * Returns current time given a timezone and state
 * @param {String} timeZone - Timezone as a String
 * @param {String} oppState - Needed to check if daylight savings is in effect
 * @param {Boolean} asDateString - Boolean to return full date string
 * @param {DateTime} selectedDateTime - DateTime to change to current timezone
 * @returns {String} time - current time in location
 */
export const getCurrentTime = (timeZone, oppState, asDateString = false, selectedDateTime = new Date()) => {
  try {
    // DST === daylight savings time
    let isDST = false
    const stateIgnoresDST = ignoreDST.includes(oppState) // get states that do not observe DST
    /* Checking if the timezone is observing DST. */
    if (!stateIgnoresDST || !oppState) {
      const janDate = new Date(2000, 0, 1) // months are zero based, year is arbitrary, use jan since DST is march - nov
      const janTimeZoneOffset = janDate.getTimezoneOffset() // difference in minutes between UTC and local
      const curDate = selectedDateTime
      const curDateTimeZoneOffset = curDate.getTimezoneOffset() // difference in minutes between UTC and local
      // if the timezone offset is the same as january, then it is not observing DST
      isDST = janTimeZoneOffset !== curDateTimeZoneOffset // detect DST observation
    }
    const currentTimeZone = timeZones.find((tZone) => tZone.name === timeZone || tZone.std === timeZone || '')
    if (!currentTimeZone) return
    const timeZoneString = isDST ? currentTimeZone.dst : currentTimeZone.std
    let currentTime = selectedDateTime.toLocaleString('en-US', { timeZone: timeZoneString })
    if (asDateString) {
      return currentTime
    }
    currentTime = selectedDateTime.toLocaleTimeString('en-US', { timeZone: timeZoneString, timeStyle: 'short' })
    return currentTime
  } catch (error) {
    consoleError(thisFile, 'timezone error: ', error.message)
    return ''
  }
}

/**
 * Calculates the appropriate text color (either black or white) based on the given background color.
 * To adjust the sensitivity use getLuminance
 *
 * @param {string} bgColor The background color in hexadecimal format.
 * @returns {string} The recommended text color ("#ffffff" for white or "#000000" for black).
 */

export const getTextColor = (bgColor) => {
  if (!bgColor) return '#ffffff'
  const getRGB = (c) => parseInt(c, 16) || c

  const getsRGB = (c) =>
    getRGB(c) / 255 <= 0.03928 ? getRGB(c) / 255 / 12.92 : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4)

  const getLuminance = (hexColor) =>
    0.0126 * getsRGB(hexColor.substr(1, 2)) + // Red
    0.7152 * getsRGB(hexColor.substr(3, 2)) + // Green
    0.0722 * getsRGB(hexColor.substr(-2)) // Blue

  const getContrast = (f, b) => {
    const L1 = getLuminance(f)
    const L2 = getLuminance(b)
    return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
  }

  const whiteContrast = getContrast(bgColor, '#ffffff')
  const blackContrast = getContrast(bgColor, '#000000')

  return whiteContrast > blackContrast ? '#ffffff' : '#000000'
}

/**
 * Gets the timezone based on the area code
 * @param {String} phoneToCheck
 * @returns {Object} timezone
 */
export const getTimezoneFromPhone = async (phoneToCheck) => {
  const results = {}
  const areaCode = phoneToCheck.substring(0, 3)
  const selectedTimeZone = phoneAreaCodes.find((timeZone) => timeZone?.[areaCode])?.[areaCode]
  results.timeZone =
    timeZones.find((tz) => {
      let timezone = tz.std
      if (timezone === 'America/Anchorage') timezone = 'AKST'
      return !!(timezone === selectedTimeZone)
    })?.name || ''
  return results
}

/**
 * Posts the given payload to the specified endpoint
 * @param {String} endpoint
 * @param {Object} payload
 * @returns
 */
export const postToInternalApi = async (endpoint, payload) => {
  try {
    const postEndpoint = new URL(endpoint, baseUrl)
    await fetch(postEndpoint.toString(), {
      method: 'POST',
      body: JSON.stringify(payload)
    })
  } catch (error) {
    consoleError(thisFile, 'postToInternalAPI error: ', error.message)
  }
}

/**
 * Returns Version
 *
 * @returns {string}
 */
export const getVersion = () => {
  if (variantToBool(process.env.STATIC_VERSION)) {
    return 'YYYY.MM.DD'
  }
  return appVersion
}

/**
 * Finds if arrays share any common elements
 * @param {array} array1
 * @param {array} array2
 * @returns {boolean} true if there are common elements
 */
export const arraysShareCommonElements = (array1, array2) => {
  if (!array1 || !array2) {
    return false
  }
  return array1.some((element) => array2.includes(element))
}

/**
 * Checks if one date is after another date
 * @param {string} date1
 * @param {string} date2
 * @returns {boolean} true if date1 is after date2
 */
export const isDateAfter = (date1, date2) => {
  const date1Date = new Date(date1)
  const date2Date = new Date(date2)
  return date1Date > date2Date
}

/**
 * Cleans unwanted edge case encoding conversions to match the file paths in google drive
 * @param {string} link
 * @returns {string} cleanedLink
 */
export const cleanEdgeCaseDocLinks = (linkToClean) => {
  const cleanedLink = decodeURIComponent(
    linkToClean.split(process.env.GOOGLE_CLOUD_STORAGE_DOCUMENTS_BUCKET)?.pop()?.replace('/o/', '')
  )
  return cleanedLink
}

/**
 * Checks if date is older than amount of time
 * @param {number} amountOfUnits number of days, weeks, months, or years
 * @param {string} unit 'days', 'weeks', 'months', or 'years'
 * @param {string} date date to check
 * @returns {boolean} true if date is older than amountOfUnits
 */
export const isDateOlderThan = (amountOfUnits, unit, date) => {
  const dateDate = new Date(date)
  const currentDate = new Date()
  const timeDiff = Math.abs(currentDate.getTime() - dateDate.getTime())
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
  switch (unit) {
    case 'days':
      return diffDays > amountOfUnits
    case 'weeks':
      return diffDays > amountOfUnits * 7
    case 'months':
      return diffDays > amountOfUnits * 30
    case 'years':
      return diffDays > amountOfUnits * 365
    default:
      consoleError(thisFile, 'isDateOlderThan error: ', 'unit not found')
      return false
  }
}

/**
 * Returns an object with a qualified property that is true if the opportunity is qualified, and a
 * missingQualifications property that is an array of objects with a section property and a missing
 * property that is an array of strings.
 * @param {Object} opportunity - the opportunity object
 * @param {Object} campaign - the campaign object
 * @param {Boolean} qualifyingQuestionsMet - true if all qualifying questions are met, false if not
 * @param {Object} user - the user object
 * @param {Boolean} requiredQuestionsAnswered - true if all required questions are answered, false if not
 * @param {Boolean} conditionalQuestionsAnswered - true if all visible conditional questions are answered, false if not
 * @param {Array} unqualifiedQuestions - array of questions that are unqualified
 * @param {Boolean} answerRestrictionsMet - true if any answer restrictions are met, false if not
 * @returns {{ qualified: boolean, missingQualifications: Array }} An object with two properties: qualified and missingQualifications.
 */
export const oppIsQualified = (
  opportunity,
  campaign,
  qualifyingQuestionsMet,
  requiredQuestionsAnswered,
  user,
  conditionalQuestionsAnswered,
  unqualifiedQuestions = [],
  answerRestrictionsMet = false
) => {
  const tempMissingQualifications = []
  let visibleConditionalsAnswered = true
  if (!conditionalQuestionsAnswered) {
    tempMissingQualifications.push({
      section: 'Unanswered Conditionals',
      missing: ['A conditional question needs answer']
    })
    visibleConditionalsAnswered = false
  }
  // clientInfoQualified is true when firstName, lastName, dob, and either email or password are populated
  let clientInfoQualified = true
  const missingClientInfo = { section: 'Client Info', missing: [] }
  if (!opportunity?.firstName) missingClientInfo.missing.push('First Name')
  if (!opportunity?.lastName) missingClientInfo.missing.push('Last Name')
  if (!opportunity?.email && !opportunity?.phone) missingClientInfo.missing.push('Email or Phone')
  if (!opportunity?.dob) missingClientInfo.missing.push('DOB')
  // if missingClientInfo.missing.length > 0, add it to tempMissingQualifications and set clientInfoQualified to false
  if (missingClientInfo.missing.length) {
    tempMissingQualifications.push(missingClientInfo)
    clientInfoQualified = false
  }
  // addressQualified is true when addressStreet, addressCity, addressState, and addressZip are populated
  let addressQualified = true
  const missingAddress = { section: 'Address', missing: [] }
  if (!opportunity?.addressStreet) missingAddress.missing.push('Street')
  if (!opportunity?.addressCity) missingAddress.missing.push('City')
  if (!opportunity?.addressState) missingAddress.missing.push('State')
  if (!opportunity?.addressZIP) missingAddress.missing.push('Zip')
  // if missingAddress.missing.length > 0, add it to tempMissingQualifications and set addressQualified to false
  if (missingAddress.missing.length) {
    tempMissingQualifications.push(missingAddress)
    addressQualified = false
  }
  // campaignNotInactive is true when campaign.status is not 'Inactive'
  let campaignNotInactive = true
  if (campaign?.status === 'inactive') {
    tempMissingQualifications.push({ section: 'Campaign Inactive', missing: [] })
    campaignNotInactive = false
  }
  // injuredPartyInfo will be true if opportunity.injuredPartyDifferent is not true,
  // or if opportunity.injuredPartyDifferent is true, and opportunity.injuredPartyFirstName,
  // opportunity.injuredPartyLastName, opportunity.injuredPartyDOB, and opportunity.injuredPartyRelation are populated
  let injuredPartyInfo = true
  if (opportunity?.injuredPartyDifferent) {
    const missingInjuredPartyInfo = { section: 'Injured Party Info', missing: [] }
    if (!opportunity?.injuredPartyFirstName) missingInjuredPartyInfo.missing.push('First Name')
    if (!opportunity?.injuredPartyLastName) missingInjuredPartyInfo.missing.push('Last Name')
    if (!opportunity?.injuredPartyDOB) missingInjuredPartyInfo.missing.push('DOB')
    if (!opportunity?.injuredPartyRelation) missingInjuredPartyInfo.missing.push('Relation')
    if (missingInjuredPartyInfo.missing.length) {
      tempMissingQualifications.push(missingInjuredPartyInfo)
      injuredPartyInfo = false
    }
  }
  // smartyStreetsVerified is true when opportunity.smartyStreetsVerified or opportunity.addressManuallyVerified is true
  let smartyStreetsVerified = true
  if (!opportunity?.smartyStreetsVerified && !opportunity?.addressManuallyVerified) {
    tempMissingQualifications.push({ section: 'Address not verified' })
    smartyStreetsVerified = false
  }
  if (!qualifyingQuestionsMet) {
    const missingQualifyingQuestions = { section: 'Qualifying Questions' }
    if (unqualifiedQuestions.length) {
      missingQualifyingQuestions.missing = unqualifiedQuestions?.map((question) => question.questionText)
    }
    tempMissingQualifications.push(missingQualifyingQuestions)
  }
  if (!requiredQuestionsAnswered) {
    tempMissingQualifications.push({ section: 'Required Questions not completed' })
  }
  if (answerRestrictionsMet) {
    tempMissingQualifications.push({ section: 'An Answer is outside of the answer restrictions' })
  }

  if (
    clientInfoQualified &&
    visibleConditionalsAnswered &&
    addressQualified &&
    campaignNotInactive &&
    injuredPartyInfo &&
    smartyStreetsVerified &&
    qualifyingQuestionsMet &&
    requiredQuestionsAnswered &&
    !answerRestrictionsMet
  ) {
    // opportunityIsQualified is true when all of the above are true
    return {
      qualified: true,
      missingQualifications: []
    }
  } else if (
    hasAccess('action:sendDocsOnInactive', user) &&
    clientInfoQualified &&
    visibleConditionalsAnswered &&
    addressQualified &&
    injuredPartyInfo &&
    smartyStreetsVerified &&
    qualifyingQuestionsMet &&
    requiredQuestionsAnswered &&
    !answerRestrictionsMet
  ) {
    // opportunityIsQualified is true when user has access to send docs on inactive campaigns and all of the above are true except for campaignNotInactive
    return {
      qualified: true,
      missingQualifications: []
    }
  }
  return {
    qualified: false,
    missingQualifications: tempMissingQualifications
  }
}

/**
 * Return a new object with all the keys of the original object except for the ones in the exclude
 * array.
 * @param obj - The object to filter
 * @param exclude - an array of keys to exclude from the object
 * @returns A new object with the keys that are not in the exclude array.
 */
export function filterObject(obj, exclude) {
  return Object.keys(obj)
    .filter((key) => !exclude.includes(key))
    .reduce((newObj, key) => {
      newObj[key] = obj[key]
      return newObj
    }, {})
}

/**
 * It takes a campaignId and a delta object, and then it makes a request to the server to check if any
 * event actions should be triggered
 * @param {String} opportunityId - the opportunity id
 * @param {String} campaignId - the campaign id
 * @param {Object} delta - the delta object
 */
export const checkEventActions = async (opportunityId, campaignId, delta = { status: 'test' }) => {
  const eventActionsResponse = await fetch(
    `/api/rest/eventActionCheck?opportunityId=${opportunityId}&campaignId=${campaignId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        delta
      })
    }
  )
  const eventActions = await eventActionsResponse.json()
  if (eventActions.triggered) {
    return eventActions.eventActions
  }
  return false
}
/**
 * Takes an interval and a range, and returns a date that is the interval ago from the current date
 * @param interval - The number of minutes, hours, days, weeks, months, or years to go back.
 * @param range - The range of time you want to get.
 * @param default - The default date to return if the interval is 0.
 * @returns A date object
 */
export const getTimeAgo = (interval, range, defaultDate = 'beginning') => {
  if (interval === 0) {
    switch (defaultDate) {
      case 'now':
        return new Date()
      case 'beginning':
      default:
        return new Date(0) // Beginning of time
    }
  }
  const timeAgo = new Date()
  switch (range) {
    case 'Minutes':
      return timeAgo.setMinutes(timeAgo.getMinutes() - interval)
    case 'Hours':
      return timeAgo.setHours(timeAgo.getHours() - interval)
    case 'Days':
      return timeAgo.setDate(timeAgo.getDate() - interval)
    case 'Weeks':
      return timeAgo.setDate(timeAgo.getDate() - interval * 7)
    case 'Months':
      return timeAgo.setMonth(timeAgo.getMonth() - interval)
    case 'Years':
      return timeAgo.setFullYear(timeAgo.getFullYear() - interval)
    default:
      return timeAgo
  }
}

/**
 * Takes an interval and a range, and returns a date that is the interval from the current date.
 * @param interval - The number of minutes, hours, days, weeks, months, or years to go back.
 * @param range - The range of time you want to get.
 * @returns A date object
 */
export const getTimeUntil = (interval, range) => {
  const timeUntil = new Date()
  switch (range) {
    case 'Minutes':
      return timeUntil.setMinutes(timeUntil.getMinutes() + interval)
    case 'Hours':
      return timeUntil.setHours(timeUntil.getHours() + interval)
    case 'Days':
      return timeUntil.setDate(timeUntil.getDate() + interval)
    case 'Weeks':
      return timeUntil.setDate(timeUntil.getDate() + interval * 7)
    case 'Months':
      return timeUntil.setMonth(timeUntil.getMonth() + interval)
    case 'Years':
      return timeUntil.setFullYear(timeUntil.getFullYear() + interval)
    default:
      return timeUntil
  }
}

const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
const getHash = customAlphabet(characters, 5)

const allowedDomains = [
  'https://pro.caseopp.com',
  'https://app.caseopp.com',
  'https://sta.caseopp.com',
  'https://sta.rdevs.com',
  'http://localhost:3000',
  'https://reciprocityadmin.com'
]

/**
 * It takes a URL, checks if it's allowed, checks if it's already in the database, and if not, creates
 * a new shortened URL and returns it
 * @param db - The database connection
 * @param url - The URL to shorten
 * @returns The shortened url
 */
export const urlShortener = async (db, url) => {
  if (!url) return 'badRequest'
  const urls = db.collection('shortenedUrls')

  const allowed = allowedDomains.some((domain) => url.startsWith(domain))
  if (!allowed) return 'badRequest'

  const existingLink = await urls.findOne({ url })
  if (existingLink) return existingLink.shortenedUrl

  const hash = getHash()
  const shortenedUrl = `${baseUrl}/s/${hash}`

  const result = await urls.insertOne({ url, shortenedUrl, hash })
  if (!result) return 'internalServerError'

  return shortenedUrl
}

/**
 * The function escapes special characters in a string by replacing them with their escaped
 * equivalents.
 * @param {string} value - The input value that needs to be escaped for special characters.
 * @returns {string} The escaped string.
 */
export const escapeSpecialChars = (value) => {
  return value
    .replace(/\n/g, '\\n')
    .replace(/"/g, '\\"')
    .replace(/\r/g, '\\r')
    .replace(/\t/g, '\\t')
    .replace(/\f/g, '\\f')
}

/**
 * Takes a JSON object and a header string and returns a formData object if the header is multipart/form-data
 * otherwise it returns the JSON object
 * @param {String} body - The body of the request
 * @param {String} header - The header of the request
 * @returns {JSON Object || FormData Object} - The body of the request
 */
export const formDataParseBodyIfRequested = (body, header) => {
  if (header.includes('multipart/form-data')) {
    try {
      const bodyAsObject = JSON.parse(body)
      const formData = new FormData()
      for (const field in bodyAsObject) {
        // skip loop if the value is a object
        if (field !== 'file' && typeof bodyAsObject[field] === 'object') continue

        // add the data to the formData
        if (field === 'file') {
          const bufferFile = Buffer.from(bodyAsObject[field])
          formData.append(field, bufferFile, 'retainer.pdf')
        } else {
          formData.append(field, bodyAsObject[field])
        }
      }
      return formData
    } catch (error) {
      // if error, return the body as is
      return body
    }
  } else {
    return body
  }
}

/**
 * Merges default columns for given user groups.
 *
 * @param {Array} allUserGroups - Array of all user groups.
 * @param {Array} userGroups - Array of user groups to merge default columns for.
 * @returns {Array} - Array of merged default columns.
 */
export const handleMergingDefaultColumnsForUserGroups = (allUserGroups, userGroups) => {
  let unionArray = []
  if (!userGroups?.length) return unionArray
  userGroups.forEach((group) => {
    const findGroupInUserGroups = allUserGroups?.find((userGroup) => userGroup?.name === group)
    if (findGroupInUserGroups) {
      const set1 = new Set(unionArray)
      const set2 = new Set(findGroupInUserGroups.defaultColumns)
      const unionSet = new Set([...set1, ...set2])
      unionArray = [...unionSet]
    }
  })
  return unionArray
}

/**
 * Takes the qualified question from the destination campaign and the answers from the origin opp and returns the number of verified answers
 * @param {Array} destinationCampaignQualifiedQuestions from destination campaign
 * @param {Object} answersFromOriginOpp from origin opp
 * @returns {number} number of verified answers
 */
export const getNumberOfVerifiedAnswersFromNewCampaign = (
  destinationCampaignQualifiedQuestions,
  answersFromOriginOpp
) => {
  // Filled out answers for origin opp
  let count = 0
  const originOppFilledOutAnswers = Object.keys(answersFromOriginOpp)
    .filter((answer) => answersFromOriginOpp[answer]?.answer && answersFromOriginOpp[answer]?.answer?.trim() !== '')
    .map((key) => ({ question: key, answer: answersFromOriginOpp[key]?.answer }))
  for (const question of destinationCampaignQualifiedQuestions) {
    const matchingAnswerSlug = originOppFilledOutAnswers.find((answer) => answer.question === question.questionSlug)
    if (matchingAnswerSlug) {
      if (question?.qualifiedOptions?.includes(matchingAnswerSlug.answer)) {
        count++
      } else if (question?.answerType === 'Select Many') {
        const matchingAnswerSlugs = matchingAnswerSlug.answer.split(' | ')
        if (matchingAnswerSlugs.every((answerSlug) => question?.qualifiedOptions?.includes(answerSlug))) {
          count++
        }
      } else if (question?.answerType === 'Select One') {
        if (variantToBool(matchingAnswerSlug.answer) === question.qualifier) {
          count++
        }
        // Count any text fields so long as they are answered
      } else if (question?.answerType === 'Text Field' && answersFromOriginOpp[question?.questionSlug]?.answer) {
        count++
      }
    }
  }
  return count
}

/**
 * Checks if a string is invalid JSON
 * @param {String} json
 * @returns {Boolean} true if the string is invalid JSON
 */
export const isInvalidJSON = (json) => {
  try {
    JSON.parse(json)
    return false
  } catch (error) {
    return true
  }
}
