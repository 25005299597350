import type { QuestionAnswerType, UserFacingOppFieldNamesImportDict } from './commonInterfaces'

export const userFacingOppFieldNamesDict = {
  address: 'Address',
  addressCity: 'City',
  addressCountry: 'Country',
  addressState: 'State',
  addressStreet: 'Street Address',
  addressUnit: 'Address Unit',
  addressZIP: 'ZIP Code',
  alternateExtension: 'Alternate Phone Extension',
  alternateName: 'Name if different than Client',
  alternatePhone: 'Alternate Phone',
  alternatePhoneExtension: 'Alternate Phone Extension',
  alternatePhoneType: 'Alternate Phone Type',
  answers: 'Answers',
  assureSignDocuments: 'AssureSign Documents',
  attorneyUser: 'Attorney',
  author: 'Author',
  autoTimeZone: 'Auto Set Time Zone',
  ballotId: 'Ballot ID',
  billToOverride: 'Campaign Bill To',
  callbackDateTime: 'Callback Time',
  campaign: 'Campaign',
  campaignGroups: 'Campaign Groups',
  campaignId: 'Campaign',
  campaignStatus: 'Campaign Status',
  campaignStatuses: 'Campaign Statuses',
  caseComment: 'Case Comments',
  caseCommentAuthor: 'Case Comment Author',
  caseManagerUser: 'Case Manager', // display
  caseStatus: 'Case Status',
  caseStatusUpdated: 'Case Status Updated',
  checkList: 'Task List',
  claimFiledDate: 'Claim Filed Date',
  claimFormSentDate: 'Claim Form EverSign Sent Date',
  claimFormSignedDate: 'Claim Form EverSign Date Field',
  claimId: 'Claim ID',
  clientComment: 'Client Comment',
  clientCommentAuthor: 'Client Comment Author',
  clientPortalLastAccessed: 'Date Client Last Logged In',
  clientPortalTokens: 'Client Portal Link',
  clientSupportSpecialistUser: 'Client Support Specialist',
  clientView: 'Co-Counsel Portal',
  comment: 'Comment',
  communicationGreeting: 'Communication Greeting',
  contactAttemptsCount: 'Contact Attempts',
  correctionalFacility: 'Correctional Facility',
  createdAt: 'Opportunity Created',
  dateSentClient: 'Doc Sent To Client',
  dateSentFirm: 'Doc Sent To Co-Counsel',
  dateSigned: 'Doc Signed',
  deadline: 'Deadline',
  displayId: 'ID',
  disqualification: 'Disqualification',
  disqualificationAdditionalInfo: 'Disqualification Additional Info',
  dob: 'Date Of Birth',
  doNotCallAltPhone: 'Do Not Call Alternate Phone',
  doNotCallPhone: 'Do Not Call Phone',
  doNotEmailPrimary: 'Do Not Email Primary',
  doNotEmailSecondary: 'Do Not Email Secondary',
  doNotMail: 'Do Not Mail',
  doNotTextAltPhone: 'Do Not Text Alternate Phone',
  doNotTextPhone: 'Do Not Text Phone',
  downloadedComms: 'Downloaded Communications',
  eaInput: 'Event Action History Results',
  email: 'Email',
  exclude: 'Exclude',
  externalId: 'External ID',
  filedCaseNumber: 'Filed Case #',
  firstName: 'First Name',
  firstRetainerSender: 'First Retainer Sent By',
  flagIds: 'Opp Flag',
  flagIdsStrict: 'Strict Flag',
  followUp: 'Follow Up',
  gender: 'Gender',
  handlingFirms: 'Handling Firms',
  incarcerated: 'Incarcerated',
  incomingPhone: 'Incoming Phone',
  injuredPartyDifferent: 'Injured Party Different',
  injuredPartyDOB: 'Injured Party Date of Birth',
  injuredPartyDOD: 'Injured Party Date of Death',
  injuredPartyFirstName: 'Injured Party First Name',
  injuredPartyGender: 'Injured Party Gender',
  injuredPartyLastName: 'Injured Party Last Name',
  injuredPartyMiddleName: 'Injured Party Middle Name',
  injuredPartyRelation: "Caller's Relationship to Injured Party",
  injuredPartySSN: 'Injured Party SSN',
  inmateId: 'Inmate ID',
  intakeUser: 'Intake Rep',
  isCase: 'Is Case',
  lastName: 'Last Name',
  leadsProviders: 'Leads Provider',
  legalAssistant: 'Legal Assistant',
  litigationSupportUser: 'Litigation Support Specialist',
  managerUser: 'Case Manager', // database
  middleName: 'Middle Name',
  name: 'Name',
  negotiatedRetainerApproval: 'Negotiated Retainer Approval',
  notes: 'Notes',
  originatingOpp: 'Originating Opp',
  origination: 'Origination',
  otherEversignSignedDate: 'Other EverSign Date Field',
  owningFirm: 'Owning Firm',
  paralegalUser: 'Paralegal',
  pcDeclinedReason: 'Reason PC Declined',
  phone: 'Phone',
  phoneExtension: 'Phone Extension',
  phoneType: 'Phone Type',
  pin: 'Pin',
  pocFiledDate: 'POC Filed',
  pocSentForSigningDate: 'POC Sent',
  pocSignedDate: 'POC Signed',
  preferredName: 'Preferred Name',
  previousPhoneNumbers: 'Previous Phone Numbers',
  priority: 'Campaign Priority',
  publisher: 'Publisher',
  questionnaireURL: 'AIS Questionnaire URL',
  retainerSignedDate: 'Retainer EverSign Date Field',
  reviewFlagResolver: 'Review Flag Resolver',
  reviewUser: 'Review User',
  secondaryEmail: 'Secondary Email',
  source: 'Source',
  status: 'Status',
  suffix: 'Suffix',
  timestamp: 'Date Created',
  timeZone: 'Time Zone',
  title: 'Title',
  turnDownDate: 'Turn Down Date',
  updated: 'Last Updated',
  updatedAt: 'Last Updated',
  user: 'User',
  verification: 'Verification Object'
}

export const userFacingOppFieldNamesImportDict = {
  addressCity: 'City',
  addressCountry: 'Country',
  addressManuallyVerified: 'Address Manually Verified',
  addressState: 'State',
  addressStreet: 'Street Address',
  addressUnit: 'Address Unit',
  addressZIP: 'ZIP Code',
  alternateExtension: 'Alternate Phone Extension',
  alternateName: 'Name if different than Client',
  alternatePhone: 'Alternate Phone',
  alternatePhoneType: 'Alternate Phone Type',
  attorneyUser: 'Attorney',
  autoTimeZone: 'Auto Set Time Zone',
  batchNumber: 'Batch Number',
  campaign: 'Campaign',
  caseComments: 'Case Comments',
  caseFlagIds: 'Case Flag IDs',
  caseStatus: 'Case Status',
  caseStatusUpdated: 'Case Status Updated',
  claimFiledDate: 'Claim Filed Date',
  claimId: 'Claim ID',
  clientPortalCustomFieldsSubmitted: 'Client Portal Custom Fields Submitted',
  clientSupportSpecialistUser: 'Client Support Specialist',
  comments: 'Comments',
  correctionalFacility: 'Correctional Facility',
  deadline: 'Deadline',
  displayId: 'Display ID',
  disqualification: 'Disqualification',
  dob: 'Date Of Birth',
  doNotCallAltPhone: 'Do Not Call Alternate Phone',
  doNotCallPhone: 'Do Not Call Phone',
  doNotEmailPrimary: 'Do Not Email Primary',
  doNotEmailSecondary: 'Do Not Email Secondary',
  doNotMail: 'Do Not Mail',
  doNotTextAltPhone: 'Do Not Text Alternate Phone',
  doNotTextPhone: 'Do Not Text Phone',
  email: 'Email',
  externalId: 'External ID',
  filedCaseNumber: 'Filed Case #',
  firstName: 'First Name',
  flagIds: 'Flag Ids',
  flags: 'Flags',
  gender: 'Gender',
  incarcerated: 'Incarcerated',
  injuredPartyDeceased: 'Injured Party Deceased',
  injuredPartyDifferent: 'Injured Party Different',
  injuredPartyDOB: 'Injured Party Date of Birth',
  injuredPartyDOD: 'Injured Party Date of Death',
  injuredPartyFirstName: 'Injured Party First Name',
  injuredPartyGender: 'Injured Party Gender',
  injuredPartyLastName: 'Injured Party Last Name',
  injuredPartyMiddleName: 'Injured Party Middle Name',
  injuredPartyRelation: "Caller's Relationship to Injured Party",
  injuredPartySSN: 'Injured Party SSN',
  inmateId: 'Inmate ID',
  intakeUser: 'Intake Rep',
  isCase: 'Is Case',
  lastName: 'Last Name',
  legalAssistant: 'Legal Assistant',
  litigationSupportUser: 'Litigation Support Specialist',
  managerUser: 'Case Manager',
  middleName: 'Middle Name',
  narrative: 'Case Narrative',
  negotiatedRetainerApproval: 'Negotiated Retainer Approval',
  notes: 'Notes',
  originatingOpp: 'Originating Opp',
  origination: 'Origination',
  originIp: 'Origin Ip',
  originURL: 'Origin URL',
  paralegalUser: 'Paralegal',
  pcDeclinedReason: 'Reason PC Declined',
  perfectedDate: 'Perfected Date',
  phone: 'Phone',
  phoneExtension: 'Phone Extension',
  phoneType: 'Phone Type',
  preferredLanguage: 'Preferred Language',
  priority: 'Priority',
  publisher: 'Publisher',
  questionnaireCompletedDate: 'Questionnaire Complete Date',
  reviewFlagResolver: 'Review Flag Resolver',
  reviewUser: 'Review User',
  round: 'Round',
  smartyStreetsVerified: 'Smarty Streets Verified',
  source: 'Source',
  status: 'Status',
  suffix: 'Suffix',
  timestamp: 'Date Created (will default to now if blank)',
  timeZone: 'Time Zone',
  turnDownDate: 'Turn Down Date',
  userConsent: 'User Consent',
  userConsentIds: 'User Consent IDs',
  userConsentType: 'User Consent Type'
  // checkList: 'Completed+ Assigned User+ Deadline+ Group+ Task+ Description | ...' TODO:will be for import/update checklists
}

export const userFacingClientInfoFieldNamesDict = {
  displayId: 'ID',
  campaign: 'Campaign',
  campaignId: 'Campaign',
  caseStatus: 'Status',
  caseStatusUpdated: 'Status Updated',
  timestamp: 'Date Created',
  user: 'User',
  title: 'Title',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  contactTime: 'Best Time To Contact',
  preferredName: 'Preferred Name',
  publisher: 'Publisher',
  suffix: 'Suffix',
  email: 'Email',
  secondaryEmail: 'Secondary Email',
  phone: 'Phone',
  phoneExtension: 'Phone Extension',
  phoneType: 'Phone Type',
  alternatePhone: 'Alternate Phone',
  alternatePhoneExtension: 'Alt. Phone Extension',
  alternatePhoneType: 'Alt. Phone Type',
  addressStreet: 'Address line 1',
  addressUnit: 'Address Line 2',
  addressCity: 'City',
  addressState: 'State',
  addressZIP: 'ZIP Code',
  ssAddressSearch: 'Address Lookup',
  timeZone: 'Time Zone',
  autoTimeZone: 'Auto Set Time Zone',
  addressCountry: 'Country',
  incarcerated: 'Incarcerated',
  inmateId: 'Inmate ID',
  correctionalFacility: 'Correctional Facility',
  dob: 'Date Of Birth',
  notes: 'Notes',
  preferredLanguage: 'Preferred Language',
  externalId: 'External ID',
  externalIds: 'External IDs',
  negotiatedRetainerApproval: 'Negotiated Retainer Approval',
  identityVerificationStatus: 'Identity Verification Status',
  injuredPartyDifferent: 'Injured Party Different',
  injuredPartyFirstName: 'Injured Party First Name',
  injuredPartyMiddleName: 'Injured Party Middle Name',
  injuredPartyLastName: 'Injured Party Last Name',
  injuredPartyDeceased: 'Injured Party Deceased',
  injuredPartyDOD: 'Injured Party Date of Death',
  injuredPartyDOB: 'Injured Party Date of Birth',
  injuredPartySuffix: 'Injured Party Suffix',
  injuredPartySSN: 'Injured Party SSN',
  injuredPartyGender: 'Injured Party Gender',
  injuredPartyRelation: "Caller's Relationship to Injured Party",
  deadline: 'Deadline',
  gender: 'Gender',
  originIp: 'Origin IP',
  originURL: 'Origin URL',
  userConsent: 'User Consent',
  userConsentType: 'User Consent Type',
  userConsentIds: 'User Consent IDs',
  managerUser: 'Case Manager',
  intakeUser: 'Intake Rep',
  paralegalUser: 'Paralegal',
  reviewUser: 'Review User',
  attorney: 'Attorney',
  legalAssistant: 'Legal Assistant',
  status: 'Status',
  pcDeclinedReason: 'Reason PC Declined',
  disqualification: 'Disqualification',
  'case.flagIds': 'Case Flag',
  coCounselDeclinedReason: 'Co-Counsel Declined Reason',
  disqualificationAdditionalInfo: 'Disqualification Additional Info',
  communicationGreeting: 'Communication Greeting',
  turnDownDate: 'Turn Down Date'
}

export const userFacingCaseFieldNamesDict = {
  ...userFacingClientInfoFieldNamesDict,
  comment: 'Comments',
  updated: 'Last Updated',
  name: 'Name',
  followUp: 'Follow Up',
  author: 'Author',
  notes: 'Notes',
  externalId: 'Ext. ID',
  flagIds: 'Flag',
  caseFlagIds: 'Case Flag',
  priority: 'Campaign Priority',
  doNotMail: 'Do Not Mail',
  doNotCallPhone: 'Do Not Call Phone',
  doNotTextPhone: 'Do Not Text Phone',
  doNotCallAltPhone: 'Do Not Call Alternate Phone',
  doNotTextAltPhone: 'Do Not Text Alternate Phone',
  doNotEmailPrimary: 'Do Not Email Primary',
  doNotEmailSecondary: 'Do Not Email Secondary',
  narrative: 'Narrative',
  case: 'Case',
  pocFiledDate: 'POC Filed Date',
  pocSignedDate: 'POC Signed Date',
  pocSentForSigningDate: 'POC Sent For Signing Date',
  questionnaireSentDate: 'Questionnaire Sent Date',
  questionnaireCompletedDate: 'Questionnaire Completed Date',
  filedCaseNumber: 'Filed Case #',
  claimId: 'Claim ID',
  ballotId: 'Ballot ID',
  assignedUser: 'Case Assignee',
  loanAmount: 'Loan Amount',
  loanProvider: 'Loan Provider',
  legalAssistant: 'Legal Assistant',
  claimFiledDate: 'Claim Filed Date',
  checkList: 'Task List'
}

export const caseHiddenColumns = [
  'callbackDateTime',
  'comments',
  'flagIds',
  'intakeUser',
  'isCase',
  'managerUser',
  'priority',
  'questionnaireURL',
  'reviewFlagResolver',
  'reviewUser',
  'score',
  'statusChangeTracker'
]

export const userFacingCampaignFieldNamesDict = {
  name: 'Name',
  description: 'Description',
  firms: 'Firms',
  slug: 'Slug',
  priority: 'Priority',
  counter: 'Counter',
  assureSignDocumentTemplateIds: 'Assure Sign Document Template Ids',
  order: 'Order',
  groups: 'Campaign Groups',
  accountManagers: 'Account Managers',
  assignableUsers: 'Assignable Users',
  defaultOpportunityStatus: 'Default Opportunity Status',
  defaultCaseStatus: 'Default Case Status',
  status: 'Status',
  initCampHistory: 'Initial Campaign History',
  forceCampHistory: 'Force Campaign History',
  coCounselEmail: 'Co-Counsel Email',
  casesGoal: 'Cases Goal',
  estimatedValue: 'Estimated Value',
  qualifiedSignedPrice: 'Qualified Signed Price',
  attorneyFeePercentage: 'Attorney Fee Percentage',
  attorneyFeeSplit: 'Attorney Fee Split',
  pricingNotes: 'Pricing Notes',
  attorneyFeeNotes: 'Attorney Fee Notes',
  dids: 'DIDs',
  disqualifications: {
    slug: 'Disqualification Slug',
    value: 'Disqualification Reason',
    instructions: 'Disqualification Instructions',
    additionalInfoRequired: 'Additional Information Required'
  },
  oppsCopyable: 'Opps Copyable',
  oppsCopyNewCampaignOptions: 'Opps Copy New Campaign Options',
  projectedConversionRate: 'Projected Conversion Rate',
  totalMarketingSpend: 'Total Marketing Spend',
  eversignTemplates: 'Eversign Templates',
  clientInfoEditing: 'Client Info Editing',
  showClientInfo: 'Show Client Info',
  questionnaireEditing: 'Questionnaire Editing',
  showQuestionnaire: 'Show Questionnaire',
  enableComments: 'Enable Comments',
  showComments: 'Show Comments',
  eversignBusinessId: 'Eversign Business ID',
  eversignRequestorName: 'Eversign Requester Name',
  eversignRequestorEmail: 'Eversign Requester Email',
  campaignNotes: 'Campaign Notes',
  separateESignWithAudit: 'Separate ESign With Audit',
  pricePerLead: 'Price Per Lead',
  createdAt: 'Created', // date
  updatedAt: 'Updated', // date
  waterfallNotes: 'Disqualification Notes',
  intakeGreeting: 'Intake Greeting',
  onboarding: 'Onboarding',
  attorneysAndUserGroups: {
    name: 'Attorney or User Group',
    user_id: 'The ID of the user'
  },
  signedCaseUserGroups: 'Signed Case User Groups',
  signedCaseRestrictionMessage: 'Signed Case Restriction Message'
}

export const opportunityCoreContactFields = {
  firstName: { type: String, tableType: 'textField' },
  middleName: { type: String, tableType: 'textField' },
  lastName: { type: String, tableType: 'textField' },
  email: { type: String, tableType: 'textField' },
  phone: { type: String, tableType: 'textField' },
  phoneType: { type: String, tableType: 'textField' },
  phoneExtension: { type: String, tableType: 'textField' },
  addressStreet: { type: String, tableType: 'textField' },
  addressUnit: { type: String, tableType: 'textField' },
  addressCity: { type: String, tableType: 'textField' },
  addressState: { type: String, tableType: 'textField' },
  addressZIP: { type: String, tableType: 'textField' },
  addressCountry: { type: String, tableType: 'textField' },
  smartyStreetsVerified: { type: Boolean, tableType: 'boolean' },
  addressManuallyVerified: { type: Boolean, tableType: 'boolean' },
  timeZone: { type: String, tableType: 'textField' },
  autoTimeZone: { type: Boolean, tableType: 'boolean' },
  incarcerated: { type: Boolean, tableType: 'boolean' },
  inmateId: { type: String, tableType: 'textField' },
  correctionalFacility: { type: String, tableType: 'textField' },
  dob: { type: Date, tableType: 'dateRange' },
  alternatePhone: { type: String, tableType: 'textField' },
  alternatePhoneExtension: { type: String, tableType: 'textField' },
  alternatePhoneType: { type: String, tableType: 'textField' },
  alternateName: { type: String, tableType: 'textField' },
  notes: { type: String, tableType: 'textField' },
  title: { type: String, tableType: 'textField' },
  suffix: { type: String, tableType: 'textField' },
  preferredName: { type: String, tableType: 'textField' },
  publisher: { type: String, tableType: 'textField' },
  secondaryEmail: { type: String, tableType: 'textField' },
  negotiatedRetainerApproval: { type: Boolean, tableType: 'boolean' },
  injuredPartyDifferent: { type: Boolean, tableType: 'boolean' },
  injuredPartyFirstName: { type: String, tableType: 'textField' },
  injuredPartyMiddleName: { type: String, tableType: 'textField' },
  injuredPartyLastName: { type: String, tableType: 'textField' },
  injuredPartyDOD: { type: Date, tableType: 'dateRange' },
  injuredPartyDOB: { type: Date, tableType: 'dateRange' },
  injuredPartySuffix: { type: String, tableType: 'textField' },
  injuredPartyRelation: { type: String, tableType: 'textField' },
  injuredPartySSN: { type: String, tableType: 'textField' },
  injuredPartyGender: { type: String, tableType: 'textField' },
  callbackDateTime: { type: Date, tableType: 'dateRange' },
  deadline: { type: Date, tableType: 'dateRange' },
  gender: { type: String, tableType: 'textField' },
  originIp: { type: String, tableType: 'textField' },
  originURL: { type: String, tableType: 'textField' },
  userConsent: { type: Boolean, tableType: 'boolean' },
  userConsentType: { type: String, tableType: 'textField' },
  userConsentIds: { type: String, tableType: 'textField' },
  disqualification: { type: String, tableType: 'textField' },
  turnDownDate: { type: Date, tableType: 'dateRange' }
}

export const opportunityWebhookPayloadFields = {
  _id: { type: String, tableType: 'textField' },
  displayId: { type: String, tableType: 'textField' },
  externalId: { type: String, tableType: 'textField' },
  pin: { type: String, tableType: 'textField' },
  flag: { type: String, tableType: 'textField' },
  flagIds: { type: Array, tableType: 'textField' },
  notes: { type: String, tableType: 'textField' },
  dateSigned: { type: Date, tableType: 'textField' },
  dateSentClient: { type: Date, tableType: 'textField' },
  dateSentFirm: { type: Date, tableType: 'textField' },
  createdAt: { type: Date, tableType: 'textField' },
  updatedAt: { type: Date, tableType: 'textField' }
}

// if adding column to oppsGrid, add to allOrderedColumns
// Future dev note: this should be documented somewhere, it took me an hour to find this list.
export const allOrderedColumns = [
  '__detail_panel_toggle__',
  'score',
  '_id',
  'displayId',
  'activeUsers',
  'flagIds',
  'case.flagIds',
  'campaignId',
  'status',
  'statusChangeTracker',
  'campaignGroups',
  'campaignStatus',
  'intakeUser',
  'paralegalUser',
  'reviewUser',
  'attorneyUser',
  'litigationSupportUser',
  'managerUser',
  'preferredLanguage',
  'checkList',
  'name',
  'preferredName',
  'suffix',
  'publisher',
  'inmateId',
  'correctionalFacility',
  'timestamp',
  'lastAgentContacted',
  'dateSentClient',
  'reviewFlagResolver',
  'dateSigned',
  'dateSentFirm',
  'updatedAt',
  'claimFormSignedDate',
  'retainerSignedDate',
  'otherEversignSignedDate',
  'claimFormSentDate',
  'followUp',
  'callbackDateTime',
  'phone',
  'phoneType',
  'alternatePhone',
  'alternatePhoneType',
  'source',
  'origination',
  'originatingOpp',
  'email',
  'secondaryEmail',
  'address',
  'comments',
  'clientComments',
  'case.caseComments',
  'dob',
  'notes',
  'priority',
  'doNotMail',
  'externalId',
  'externalIds',
  'pin',
  'questionnaireURL',
  'clientView',
  'case.caseStatus',
  'case.caseStatusUpdated',
  'pocSentForSigningDate',
  'pocSignedDate',
  'pocFiledDate',
  'clientPortalLastAccessed',
  'filedCaseNumber',
  'claimId',
  'ballotId',
  'isCase',
  'timeZone',
  'currentTime',
  'doNotCallPhone',
  'doNotCallAltPhone',
  'doNotTextPhone',
  'doNotTextAltPhone',
  'doNotEmailPrimary',
  'doNotEmailSecondary',
  'injuredParty',
  'injuredPartyGender',
  'gender',
  'deadline',
  'injuredPartyDeceased',
  'injuredPartyDOB',
  'injuredPartyDOD',
  'injuredPartyRelation',
  'contactAttemptsCount',
  'case.legalAssistant',
  'injuredPartyDifferent',
  'clientPortalTokens',
  'case.claimFiledDate',
  'case.batchNumber',
  'case.perfectedDate',
  'case.questionnaireCompletedDate',
  'case.questionnaireStatus',
  'intakeQuestionnaireStatus',
  'firstRetainerSender',
  'case.assignedUser',
  'disqualification.slug',
  'disqualification.additionalInfo',
  'lastDisposition.reason',
  'lastDisposition.category',
  'campaignDetails.caseType',
  'whoRespondedLast',
  'clientLastResponded',
  'lastAgentContactedSMS',
  'lastAgentContactedEmail',
  'lastAgentContactedPhone',
  'lastClientContactedSMS',
  'lastClientContactedEmail',
  'lastClientContactedPhone',
  'firstContactDate',
  'previousPhoneNumbers',
  'turnDownDate'
]

export const answerTypes: QuestionAnswerType[] = [
  'Boolean',
  'Date',
  'Number',
  'Text Field',
  'Secret',
  'Select Many',
  'Select One',
  'Repeating Question',
  'No Answer'
]

export const answerFormats = [
  { value: 'Email', label: 'Email' },
  { value: 'Phone', label: 'Phone' },
  { value: 'Regex', label: 'Regex' },
  { value: 'No Format', label: 'No Format', default: true }
]

export const languages = [
  'Spanish',
  'Afrikaans',
  'Albanian',
  'Arabic',
  'Aragonese',
  'Armenian',
  'Azerbaijani',
  'Bambara',
  'Basque',
  'Belarusian',
  'Bengali',
  'Bosnian',
  'Breton',
  'Bulgarian',
  'Burmese',
  'Catalan',
  'Chechen',
  'Chinese',
  'Cornish',
  'Corsican',
  'Croatian',
  'Czech',
  'Danish',
  'Dutch',
  'English',
  'Estonian',
  'Fijian',
  'Finnish',
  'French',
  'Gaelic',
  'Galician',
  'Georgian',
  'German',
  'Greek (Modern)',
  'Creole',
  'Hebrew',
  'Hindi',
  'Hungarian',
  'Icelandic',
  'Indonesian',
  'Irish',
  'Italian',
  'Japanese',
  'Kashmiri',
  'Korean',
  'Kurdish',
  'Lao',
  'Latin',
  'Latvian',
  'Lithuanian',
  'Macedonian',
  'Malay',
  'Maltese',
  'Maori',
  'Romanian',
  'Mongolian',
  'Navajo, Navaho',
  'Nepali',
  'Norwegian',
  'Punjabi',
  'Persian',
  'Polish',
  'Portuguese',
  'Russian',
  'Samoan',
  'Sardinian',
  'Serbian',
  'Slovak',
  'Slovenian',
  'Somali',
  'Sundanese',
  'Swahili',
  'Swedish',
  'Tamil',
  'Thai',
  'Tibetan',
  'Turkish',
  'Uighur',
  'Ukrainian',
  'Urdu',
  'Uzbek',
  'Vietnamese',
  'Welsh',
  'Yiddish',
  'Yoruba',
  'Zulu'
]

export const disqualifiedOppStatuses = ['disqualified', 'dnq_move', 'dnq_firm_td']

export const restrictedOppStatuses = [
  'eSigWait',
  'qualifiedSent',
  'Retained_Hybrid',
  'Retained_In_House',
  'coCounselDecline',
  'Ready_For_Litigation',
  'firmDeclined',
  'pc_declined_after_signing',
  'pc_declined_after_switching_firms',
  'turn_down_never_signed',
  'approvedRetainer',
  'case_settled',
  'Addendum_Sent_for_Signature',
  'dnq_firm_td'
]

export const restrictedCaseStatuses = ['Co-Counsel_Hold', 'Case_Settled', 'Claim_Filed', 'Perfected_File']

export const ignoredDupeCheckerNames = ['unk', 'unknown', 'there']

export const states = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'American Samoa', abbreviation: 'AS' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'District Of Columbia', abbreviation: 'DC' },
  { name: 'Federated States Of Micronesia', abbreviation: 'FM' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Guam', abbreviation: 'GU' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Marshall Islands', abbreviation: 'MH' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Northern Mariana Islands', abbreviation: 'MP' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Palau', abbreviation: 'PW' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Puerto Rico', abbreviation: 'PR' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virgin Islands', abbreviation: 'VI' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' }
]

export const defaultPhoneTypes = ['Home', 'Work', 'Cell']

export const defaultTitles = ['Mr.', 'Mrs.', 'Ms.', 'Miss']

export const defaultClientTypes = ['Firm', 'Lead Provider', 'Marketer', 'Other']

export const defaultSuffixes = ['Sr.', 'Jr.', 'III', 'IV', 'V']

export const caseSortFieldKeys = [
  // keys of case that we might sort by
  'assignedUser',
  'caseStatus',
  'caseStatusUpdated',
  'filedCaseNumber',
  'claimId',
  'ballotId',
  'pocFiledDate',
  'pocSentForSigningDate',
  'pocSignedDate',
  'questionnaireCompletedDate',
  'questionnaireSentDate',
  'casePriority',
  'loanAmount',
  'loanProvider'
]

export const timeZones = [
  // Possible time zone returns from smarty streets along with time zone abbreviations for both standard and daylight times
  { name: 'Alaska', dst: 'America/Anchorage', std: 'America/Anchorage' },
  { name: 'Atlantic', dst: 'America/Halifax', std: 'America/Halifax' },
  { name: 'Eastern', dst: 'America/New_York', std: 'EST' },
  { name: 'Central', dst: 'America/Chicago', std: 'CST' },
  { name: 'Hawaii', dst: 'Pacific/Honolulu', std: 'HST' },
  { name: 'Mountain', dst: 'America/Denver', std: 'MST' },
  { name: 'Pacific', dst: 'America/Los_Angeles', std: 'PST' },
  { name: 'Samoa', dst: 'Pacific/Samoa', std: 'SST' }
]

export const genders = ['', 'Male', 'Female', 'Other']

export const ignoreDST = [
  // States that ignore daylight savings time. Used for determining opportunity time
  'Arizona',
  'AZ',
  'Hawaii',
  'HI'
]
export const neverNeedToBeMergeFields = [
  '__v',
  '_id',
  '__typename',
  'answers',
  'assignedUser',
  'assureSignDocuments',
  // 'campaignId', // this is needed for the merge field in an integration
  'caseComments',
  'caseCommentExcerpts',
  'caseStatusUpdated',
  'caseStatusLabel',
  'clientCommentExcerpt',
  'clientComments',
  'commentExcerpt',
  'comments',
  'doNotMail',
  'Do Not Call Phone',
  'Do Not Text Phone',
  'Do Not Call Alternate Phone',
  'Do Not Text Alternate Phone',
  'Do Not Email Primary',
  'Do Not Email Secondary',
  'eversignDocuments',
  'errors',
  'flag',
  'flagIds',
  'forceHistory',
  'forceCampHistory',
  'head',
  'history',
  'id',
  '$init',
  'initCampHistory',
  'initHistory',
  'intakeQA',
  'isCase',
  'isNew',
  'lockedUser',
  'questionsAndAnswers',
  'reviewUser',
  'paralegalUser',
  'scrambled',
  'skipEventActions',
  'tokens',
  'clientPortalCustomFieldsSubmitted'
]

export const emailSignatureMergeFields = [
  { displayName: 'First Name', value: 'userFirstName' },
  { displayName: 'Last Name', value: 'userLastName' },
  { displayName: 'Job Title', value: 'userJobTitle' },
  { displayName: 'Reply Email Address', value: 'replyEmailAddress' },
  { displayName: 'All Signature Fields', value: 'emailSignature' }
]

export const oppSideBarLabels = {
  clientInfo: 'Client Info',
  questions: 'Questions',
  history: 'History',
  documents: 'Documents',
  comments: 'Comments',
  finance: 'Finance',
  meta: 'Meta',
  caseDetails: 'Case Details',
  review: 'Review',
  medical: 'Medical',
  isCase: 'Is Case',
  supportArticles: 'Support Articles',
  addNotification: 'Add Notification',
  clientPortalLink: 'Client Portal Link',
  checkForDuplicates: 'Check For Duplicates',
  injuredPartyDifferent: 'Injured Party Different',
  copyToDifferentCampaign: 'Copy To Different Campaign',
  scheduleCallback: 'Schedule Callback'
}

export const oppTabLabels = {
  clientInfo: 'Client Info',
  questions: 'Questions',
  history: 'History',
  documents: 'Documents',
  comments: 'Comments',
  communications: 'Communications',
  transactions: 'Transactions',
  caseDetails: 'Case Details'
}
export const fieldsWithTimestampValues = [
  'createdAt',
  'claimFiledDate',
  'dateSentClient',
  'dateSentFirm',
  'dateSigned',
  'dob',
  'pocFiledDate',
  'pocSentForSigningDate',
  'pocSignedDate',
  'perfectedDate',
  'questionnaireSentDate',
  'questionnaireCompletedDate',
  'timestamp',
  'updated',
  'updatedAt',
  'injuredPartyDOB',
  'injuredPartyDOD',
  'deadline',
  'turnDownDate'
]
export const booleanFields = [
  'doNotMail',
  'autoTimeZone',
  'doNotCallPhone',
  'doNotTextPhone',
  'doNotCallAltPhone',
  'doNotTextAltPhone',
  'doNotEmailPrimary',
  'doNotEmailSecondary',
  'isCase',
  'injuredPartyDifferent',
  'addressManuallyVerified',
  'smartyStreetsVerified',
  'userConsent',
  'clientPortalCustomFieldsSubmitted',
  'injuredPartyDeceased',
  'injuredPartyDifferent',
  'incarcerated'
]

export const dateRangeFields = [
  'timestamp',
  'updated',
  'claimFiledDate',
  'dateSentClient',
  'dateSentFirm',
  'dateSigned',
  'pocSentForSigningDate',
  'pocSignedDate',
  'pocFiledDate',
  'clientPortalLastAccessed',
  'perfectedDate',
  'questionnaireCompletedDate'
]

export const campaignFields = [
  { field: 'firms', text: 'Firms' },
  { field: 'priority', text: 'Priority' },
  { field: 'groups', text: 'Groups' },
  { field: 'dids', text: 'DIDs' },
  { field: 'smsTemplates', text: 'SMS templates' },
  { field: 'coCounselPortal', text: 'Co-Counsel Portal' },
  { field: 'intakeQuestions', text: 'Intake Questions' },
  { field: 'caseQuestions', text: 'Case Questions' },
  { field: 'checkList', text: 'Case Progress Checklist' },
  { field: 'assignableUsers', text: 'Assignable User List' },
  { field: 'casesGoal', text: 'Case Goal' },
  { field: 'status', text: 'Campaign Status' },
  { field: 'coCounselEmail', text: 'Co-Counsel Email' },
  { field: 'disqualifications', text: 'Disqualifications' },
  { field: 'projectedConversionRate', text: 'Projected Conversion Rate' },
  { field: 'totalMarketingSpend', text: 'Marketing Budget' },
  { field: 'oppsCopyable', text: 'Copy Opps Permission' },
  { field: 'oppsCopyNewCampaignOptions', text: 'Allowed Campaigns To Copy To' },
  { field: 'flags', text: 'Flags' },
  { field: 'statuses', text: 'Statuses' },
  { field: 'eventActions', text: 'Event Actions' },
  { field: 'assureSignDocumentTemplateIds', text: 'AssureSign Documents' },
  { field: 'eversignDocs', text: 'Eversign Documents' },
  { field: 'docTemplates', text: 'Document Templates' },
  { field: 'attorneyFeeSplit', text: 'Attorney Fee Split' },
  { field: 'emailTemplates', text: 'Email Templates' },
  { field: 'communicationGreeting', text: 'Communication Greeting' },
  { field: 'clientPortalFields', text: 'Client Portal Fields' },
  {
    field: 'attorneysAndUserGroups',
    text: 'Attorney and User Groups',
    required: false,
    dbField: 'attorneysAndUserGroups',
    default: ''
  },
  { field: 'defaultOpportunityStatus', text: 'Default Opportunity Status' },
  { field: 'defaultCaseStatus', text: 'Default Case Status' },
  { field: 'signedCaseUserGroups', text: 'Signed Case User Groups' },
  { field: 'signedCaseRestrictionMessage', text: 'Signed Case Restriction Message' }
]

export const userActionsDictionary = [
  { value: 'sentEmail', displayName: 'Sent Email' },
  { value: 'sentText', displayName: 'Sent Text' },
  { value: 'openedOpportunity', displayName: 'Opened Opportunity' },
  { value: 'createdOpportunity', displayName: 'Created Opportunity' },
  { value: 'createdComment', displayName: 'Created Comment' },
  { value: 'phoneCallMade', displayName: 'Made Phone Call' },
  { value: 'phoneCallReceived', displayName: 'Received Phone Call' },
  { value: 'documentSent', displayName: 'Sent Document' },
  { value: 'opportunitySearch', displayName: 'Searched Opportunities' },
  { value: 'opportunityUpdate', displayName: 'Opportunity Updated' }
]

export const smsHumanReadable = {
  type: 'Message Type',
  opportunityDisplayId: 'Opportunity ID',
  time: 'Time Sent/Received',
  subject: 'Subject',
  text: 'Message',
  from: 'From',
  lastRespondent: 'Last Responder',
  contactNumber: 'Contact Number'
}

export const opportunityOriginations = [
  { id: 'Phone', name: 'Phone' },
  { id: 'API', name: 'API' },
  { id: 'Import', name: 'Import Tool' },
  { id: 'Email', name: 'Email' },
  { id: 'liveChat', name: 'Live Chat' },
  { id: 'copied', name: 'Copied' }
]

export const phoneAreaCodes = [
  { 201: 'EST' },
  { 202: 'EST' },
  { 203: 'EST' },
  { 204: 'CST' },
  { 205: 'CST' },
  { 206: 'PST' },
  { 207: 'EST' },
  { 208: 'MST' },
  { 209: 'PST' },
  { 210: 'CST' },
  { 212: 'EST' },
  { 213: 'PST' },
  { 214: 'CST' },
  { 215: 'EST' },
  { 216: 'EST' },
  { 217: 'CST' },
  { 218: 'CST' },
  { 219: 'CST' },
  { 220: 'EST' },
  { 223: 'EST' },
  { 224: 'CST' },
  { 225: 'CST' },
  { 228: 'CST' },
  { 229: 'EST' },
  { 231: 'EST' },
  { 234: 'EST' },
  { 239: 'EST' },
  { 240: 'EST' },
  { 248: 'EST' },
  { 251: 'CST' },
  { 252: 'EST' },
  { 253: 'PST' },
  { 254: 'CST' },
  { 256: 'CST' },
  { 260: 'EST' },
  { 262: 'CST' },
  { 267: 'EST' },
  { 269: 'EST' },
  { 270: 'CST' },
  { 272: 'EST' },
  { 276: 'EST' },
  { 279: 'PST' },
  { 281: 'CST' },
  { 301: 'EST' },
  { 302: 'EST' },
  { 303: 'MST' },
  { 304: 'EST' },
  { 305: 'EST' },
  { 307: 'MST' },
  { 308: 'CST' },
  { 309: 'CST' },
  { 310: 'PST' },
  { 312: 'CST' },
  { 313: 'EST' },
  { 314: 'CST' },
  { 315: 'EST' },
  { 316: 'CST' },
  { 317: 'EST' },
  { 318: 'CST' },
  { 319: 'CST' },
  { 320: 'CST' },
  { 321: 'EST' },
  { 323: 'PST' },
  { 325: 'CST' },
  { 326: 'EST' },
  { 330: 'EST' },
  { 331: 'CST' },
  { 332: 'EST' },
  { 334: 'CST' },
  { 336: 'EST' },
  { 337: 'CST' },
  { 339: 'EST' },
  { 341: 'PST' },
  { 346: 'CST' },
  { 347: 'EST' },
  { 351: 'EST' },
  { 352: 'EST' },
  { 360: 'PST' },
  { 361: 'CST' },
  { 364: 'CST' },
  { 380: 'EST' },
  { 385: 'MST' },
  { 386: 'EST' },
  { 369: 'PST' },
  { 401: 'EST' },
  { 402: 'CST' },
  { 404: 'EST' },
  { 405: 'CST' },
  { 406: 'MST' },
  { 407: 'EST' },
  { 408: 'PST' },
  { 409: 'CST' },
  { 410: 'EST' },
  { 412: 'EST' },
  { 413: 'EST' },
  { 414: 'CST' },
  { 415: 'PST' },
  { 417: 'CST' },
  { 419: 'EST' },
  { 423: 'EST' },
  { 424: 'PST' },
  { 425: 'PST' },
  { 430: 'CST' },
  { 432: 'CST' },
  { 434: 'EST' },
  { 435: 'MST' },
  { 440: 'EST' },
  { 442: 'PST' },
  { 443: 'EST' },
  { 445: 'EST' },
  { 447: 'CST' },
  { 448: 'EST' },
  { 456: '' },
  { 458: 'PST' },
  { 463: 'EST' },
  { 464: 'CST' },
  { 469: 'CST' },
  { 470: 'EST' },
  { 475: 'EST' },
  { 478: 'EST' },
  { 479: 'CST' },
  { 480: 'MST' },
  { 484: 'EST' },
  { 500: '' },
  { 501: 'CST' },
  { 502: 'EST' },
  { 503: 'PST' },
  { 504: 'CST' },
  { 505: 'MST' },
  { 507: 'CST' },
  { 508: 'EST' },
  { 509: 'PST' },
  { 510: 'PST' },
  { 512: 'CST' },
  { 513: 'EST' },
  { 515: 'CST' },
  { 516: 'EST' },
  { 517: 'EST' },
  { 518: 'EST' },
  { 520: 'MST' },
  { 521: '' },
  { 530: 'PST' },
  { 531: 'CST' },
  { 533: '' },
  { 534: 'CST' },
  { 539: 'CST' },
  { 540: 'EST' },
  { 541: 'PST' },
  { 544: '' },
  { 551: 'EST' },
  { 559: 'PST' },
  { 561: 'EST' },
  { 562: 'PST' },
  { 563: 'CST' },
  { 564: 'PST' },
  { 566: '' },
  { 567: 'EST' },
  { 570: 'EST' },
  { 571: 'EST' },
  { 572: 'CST' },
  { 573: 'CST' },
  { 574: 'EST' },
  { 575: 'MST' },
  { 580: 'CST' },
  { 582: 'EST' },
  { 585: 'EST' },
  { 586: 'EST' },
  { 588: '' },
  { 601: 'CST' },
  { 602: 'MST' },
  { 603: 'EST' },
  { 605: 'CST' },
  { 606: 'EST' },
  { 607: 'EST' },
  { 608: 'CST' },
  { 609: 'EST' },
  { 610: 'EST' },
  { 611: '' },
  { 612: 'CST' },
  { 614: 'EST' },
  { 615: 'CST' },
  { 616: 'EST' },
  { 617: 'EST' },
  { 618: 'CST' },
  { 619: 'PST' },
  { 620: 'CST' },
  { 623: 'MST' },
  { 626: 'PST' },
  { 628: 'PST' },
  { 629: 'CST' },
  { 630: 'CST' },
  { 631: 'EST' },
  { 636: 'CST' },
  { 640: 'EST' },
  { 641: 'CST' },
  { 646: 'EST' },
  { 650: 'PST' },
  { 651: 'CST' },
  { 656: 'EST' },
  { 657: 'PST' },
  { 659: 'CST' },
  { 660: 'CST' },
  { 661: 'PST' },
  { 662: 'CST' },
  { 667: 'EST' },
  { 669: 'PST' },
  { 678: 'EST' },
  { 680: 'EST' },
  { 681: 'EST' },
  { 682: 'CST' },
  { 689: 'EST' },
  { 700: '' },
  { 701: 'CST' },
  { 702: 'PST' },
  { 703: 'EST' },
  { 704: 'EST' },
  { 706: 'EST' },
  { 707: 'PST' },
  { 708: 'CST' },
  { 710: '' },
  { 712: 'CST' },
  { 713: 'CST' },
  { 714: 'PST' },
  { 715: 'CST' },
  { 716: 'EST' },
  { 717: 'EST' },
  { 718: 'EST' },
  { 719: 'MST' },
  { 720: 'MST' },
  { 724: 'EST' },
  { 725: 'PST' },
  { 726: 'CST' },
  { 727: 'EST' },
  { 731: 'CST' },
  { 732: 'EST' },
  { 734: 'EST' },
  { 737: 'CST' },
  { 740: 'EST' },
  { 743: 'EST' },
  { 747: 'PST' },
  { 753: 'EST' },
  { 754: 'EST' },
  { 757: 'EST' },
  { 760: 'PST' },
  { 762: 'EST' },
  { 763: 'CST' },
  { 765: 'EST' },
  { 769: 'CST' },
  { 770: 'EST' },
  { 771: 'EST' },
  { 772: 'EST' },
  { 773: 'CST' },
  { 774: 'EST' },
  { 775: 'PST' },
  { 779: 'CST' },
  { 781: 'EST' },
  { 785: 'CST' },
  { 786: 'EST' },
  { 787: 'AST' },
  { 800: '' },
  { 801: 'MST' },
  { 802: 'EST' },
  { 803: 'EST' },
  { 804: 'EST' },
  { 805: 'PST' },
  { 806: 'CST' },
  { 808: 'HST' },
  { 809: 'AST' },
  { 810: 'EST' },
  { 812: 'EST' },
  { 813: 'EST' },
  { 814: 'EST' },
  { 815: 'CST' },
  { 816: 'CST' },
  { 817: 'CST' },
  { 818: 'PST' },
  { 820: 'PST' },
  { 826: 'EST' },
  { 828: 'EST' },
  { 830: 'CST' },
  { 831: 'PST' },
  { 832: 'CST' },
  { 833: '' },
  { 838: 'EST' },
  { 839: 'EST' },
  { 840: 'PST' },
  { 843: 'EST' },
  { 844: '' },
  { 845: 'EST' },
  { 847: 'CST' },
  { 848: 'EST' },
  { 850: 'CST' },
  { 854: 'EST' },
  { 855: '' },
  { 856: 'EST' },
  { 857: 'EST' },
  { 858: 'PST' },
  { 859: 'EST' },
  { 860: 'EST' },
  { 862: 'EST' },
  { 863: 'EST' },
  { 864: 'EST' },
  { 865: 'EST' },
  { 866: '' },
  { 870: 'CST' },
  { 872: 'CST' },
  { 877: '' },
  { 878: 'EST' },
  { 880: '' },
  { 901: 'CST' },
  { 903: 'CST' },
  { 904: 'EST' },
  { 906: 'EST' },
  { 907: 'AKST' },
  { 908: 'EST' },
  { 909: 'PST' },
  { 910: 'EST' },
  { 912: 'EST' },
  { 913: 'CST' },
  { 914: 'EST' },
  { 915: 'MST' },
  { 916: 'PST' },
  { 917: 'EST' },
  { 918: 'CST' },
  { 919: 'EST' },
  { 920: 'CST' },
  { 925: 'PST' },
  { 928: 'MST' },
  { 929: 'EST' },
  { 930: 'EST' },
  { 931: 'CST' },
  { 934: 'EST' },
  { 936: 'CST' },
  { 937: 'EST' },
  { 938: 'CST' },
  { 940: 'CST' },
  { 941: 'EST' },
  { 943: 'EST' },
  { 945: 'CST' },
  { 947: 'EST' },
  { 949: 'PST' },
  { 951: 'PST' },
  { 952: 'CST' },
  { 954: 'EST' },
  { 956: 'CST' },
  { 959: 'EST' },
  { 970: 'MST' },
  { 971: 'PST' },
  { 972: 'CST' },
  { 973: 'EST' },
  { 978: 'EST' },
  { 979: 'CST' },
  { 980: 'EST' },
  { 984: 'EST' },
  { 985: 'CST' },
  { 986: 'MST' },
  { 989: 'EST' }
]

export const fieldIndexOrder = [
  'head',
  '_id',
  'campaignId',
  'status',
  'displayId',
  'updated',
  'timestamp',
  'comments.comment',
  'comments.user',
  'firstName',
  'lastName',
  'email',
  'secondaryEmail',
  'phone',
  'alternatePhone',
  'managerUser',
  'reviewUser',
  'paralegalUser',
  'injuredPartyLastName',
  'injuredPartSSN',
  'injuredPartyGender',
  'isCase',
  'emails.handled'
]

export const allowedGridStringOperators = [
  // 'contains', disabling as this causes very slow performance
  'equals',
  // 'startsWith', disabling as this causes very slow performance
  // 'endsWith', disabling as this causes very slow performance
  'isEmpty',
  'isNotEmpty',
  'isAnyOf'
]

export const allowedGridDateOperators = [
  // 'is',
  // 'isNot',
  'before',
  'after',
  'onOrBefore',
  'onOrAfter',
  'isEmpty',
  'isNotEmpty'
]

export const conditionalOperators = [
  { id: 'contains', name: 'Contains', types: ['Select Many'] },
  { id: 'doesNotContain', name: 'Does Not Contain', types: ['Select Many'] },
  { id: 'isAnyOf', name: 'Is Any Of', types: ['Select Many', 'Select One'] },
  { id: 'isNotAnyOf', name: 'Is Not Any Of', types: ['Select Many', 'Select One'] },
  { id: 'is', name: 'Is', types: ['Boolean', 'Number', 'Text Field', 'Select Many', 'Select One'] },
  { id: 'isNot', name: 'Is Not', types: ['Boolean', 'Number', 'Text Field', 'Select Many', 'Select One'] },
  { id: 'isEmpty', name: 'Is Empty', types: ['Text Field', 'Number', 'Select Many', 'Select One', 'Secret'] },
  { id: 'isNotEmpty', name: 'Is Not Empty', types: ['Text Field', 'Number', 'Select Many', 'Select One', 'Secret'] },
  { id: 'greaterThan', name: 'Greater Than', types: ['Number'] },
  { id: 'greaterThanOrEqualTo', name: 'Greater Than Or Equal To', types: ['Number'] },
  { id: 'lessThan', name: 'Less Than', types: ['Number'] },
  { id: 'lessThanOrEqualTo', name: 'Less Than Or Equal To', types: ['Number'] },
  { id: 'isOlderThan', name: 'Is Older Than', types: ['Date'] },
  { id: 'isNewerThan', name: 'Is Newer Than', types: ['Date'] },
  { id: 'isBefore', name: 'Is Before', types: ['Date'] },
  { id: 'isAfter', name: 'Is After', types: ['Date'] }
]

export const conditionalOperatorValueTypes = [
  {
    type: 'Boolean',
    fieldType: 'select',
    optionTypes: 'static',
    options: [
      { value: 'true', label: 'True' },
      { value: 'false', label: 'False' }
    ]
  },
  {
    type: 'Number',
    fieldType: 'number'
  },
  {
    type: 'Text Field',
    fieldType: 'text'
  },
  {
    type: 'Select Many',
    fieldType: 'select',
    optionTypes: 'dynamic'
  },
  {
    type: 'Select One',
    fieldType: 'select',
    optionTypes: 'dynamic'
  },
  {
    type: 'Date',
    fieldType: 'date'
  },
  {
    type: 'Secret',
    fieldType: 'Secret'
  }
]

/**
 * The function `validationRegex` uses regular expressions to check different values and ensure they meet specific requirements on how they are built.
 * @param email - Email address to test, this is based on RFC 2822 Internet Message Format standard set by IETF, Internet Engineering Task Force , https://www.ietf.org/rfc/rfc2822.txt
 * @param phone - Phone number to test - 'Phone numbers should be in ###-###-#### format with no spaces at the beginning or end.'
 * @param name - Name to test - 'Names should only have letters, hyphens, or spaces between words; No spaces at the beginning or end.'
 * @param ssn - Social Security Number to test - 'SSN should be in ###-##-#### format with no spaces at the beginning or end.'
 */

export const validationRegex = {
  phone: {
    regex: /^\d{3}-\d{3}-\d{4}$/g,
    message: 'Phone numbers should be in ###-###-#### format with no spaces at the beginning or end.'
  },
  name: {
    regex: /^[A-Za-zÀ-ÖØ-öø-ÿ'-]+(['. - ][A-Za-zÀ-ÖØ-öø-ÿ'-]+)*$/g,
    message: 'Names should only have letters, hyphens, or spaces between words; No spaces at the beginning or end.'
  },
  ssn: {
    regex: /^\d{3}-\d{2}-\d{4}$/g,
    message: 'SSN should be in ###-##-#### format with no spaces at the beginning or end.'
  }
}

export const roleNamesInDB = {
  Admin: 'admin',
  'Admin Portal Admin': 'adminPortalAdmin',
  Attorney: 'attorney',
  'Case Managers': 'caseManager',
  Client: 'client',
  CoCounsel: 'coCounsel',
  Firm: 'firm',
  'Firm Admin': 'firmAdmin',
  'Intake Agent': 'intakeAgent',
  'Legal Assistant': 'legalAssistant',
  'Litigation Support Specialist': 'litigationSupportUser',
  Paralegal: 'paralegal',
  Partner: 'partner',
  'Remote Agent': 'remoteIntake',
  'Review Specialist': 'reviewSpecialist',
  'Super Admin': 'superAdmin',
  Supervisor: 'supervisor',
  Trainee: 'trainee'
}

// Atlas search can only sort by dates and numbers
export const atlasSortableFields = [
  {
    field: 'updated',
    type: 'date',
    pivot: new Date('2019-01-01').getTime() // milliseconds since 1/1/2019 (start of caseopp)
  },
  {
    field: 'timestamp',
    type: 'date',
    pivot: new Date('2019-01-01').getTime() // milliseconds since 1/1/2019 (start of caseopp)
  },
  {
    field: 'claimFiledDate',
    type: 'date',
    pivot: new Date('2019-01-01').getTime() // milliseconds since 1/1/2019 (start of caseopp)
  },
  {
    field: 'dateSentClient',
    type: 'date',
    pivot: new Date('2019-01-01').getTime() // milliseconds since 1/1/2019 (start of caseopp)
  },
  {
    field: 'dateSigned',
    type: 'date',
    pivot: new Date('2019-01-01').getTime() // milliseconds since 1/1/2019 (start of caseopp)
  },
  {
    field: 'dateSentFirm',
    type: 'date',
    pivot: new Date('2019-01-01').getTime() // milliseconds since 1/1/2019 (start of caseopp)
  },
  {
    field: 'deadline',
    type: 'date',
    pivot: new Date('2019-01-01').getTime() // milliseconds since 1/1/2019 (start of caseopp)
  },
  {
    field: 'dob',
    type: 'date',
    pivot: new Date('1900-01-01').getTime() // milliseconds since 1/1/1900 (start of possible DOBs)
  },
  {
    field: 'injuredPartyDOB',
    type: 'date',
    pivot: new Date('1900-01-01').getTime() // milliseconds since 1/1/1900 (start of possible DOBs)
  },
  {
    field: 'injuredPartyDOD',
    type: 'date',
    pivot: new Date('1900-01-01').getTime() // milliseconds since 1/1/1900 (start of possible DODs)
  },
  {
    field: 'followUp',
    type: 'number',
    ascPivot: 0,
    descPivot: 100
  },
  {
    field: 'createdAt',
    type: 'date',
    pivot: new Date('2019-01-01').getTime() // milliseconds since 1/1/2019 (start of caseopp)
  },
  {
    field: 'pocFiledDate',
    type: 'date',
    pivot: new Date('2019-01-01').getTime() // milliseconds since 1/1/2019 (start of caseopp)
  },
  {
    field: 'pocSignedDate',
    type: 'date',
    pivot: new Date('2019-01-01').getTime() // milliseconds since 1/1/2019 (start of caseopp)
  },
  {
    field: 'pocSentForSigningDate',
    type: 'date',
    pivot: new Date('2019-01-01').getTime() // milliseconds since 1/1/2019 (start of caseopp)
  },
  {
    field: 'clientPortalLastAccessed',
    type: 'date',
    pivot: new Date('2019-01-01').getTime() // milliseconds since 1/1/2019 (start of caseopp)
  },
  {
    field: 'callbackDateTime',
    type: 'date',
    pivot: new Date('2019-01-01').getTime() // milliseconds since 1/1/2019 (start of caseopp)
  },
  {
    field: 'contactAttemptsCount',
    type: 'number',
    ascPivot: 0,
    descPivot: 10000
  },
  {
    field: 'perfectedDate',
    type: 'date',
    pivot: new Date('2019-01-01').getTime() // milliseconds since 1/1/2019 (start of perfectedDate)
  },
  {
    field: 'questionnaireCompletedDate',
    type: 'date',
    pivot: new Date('2019-01-01').getTime() // milliseconds since 1/1/2019 (start of questionnaireCompletedDate)
  }
]

export const clientPortalFields = [
  'title',
  'firstName',
  'middleName',
  'lastName',
  'suffix',
  'preferredName',
  'dob',
  'gender',
  'phone',
  'phoneExtension',
  'phoneType',
  'doNotCallPhone',
  'doNotTextPhone',
  'alternatePhone',
  'alternatePhoneExtension',
  'alternatePhoneType',
  'doNotCallAltPhone',
  'doNotTextAltPhone',
  'email',
  'doNotEmailPrimary',
  'secondaryEmail',
  'doNotEmailSecondary',
  'contactTime',
  'case',
  'case.answers',
  'case.narrative',
  'case.clientPortalCustomFieldsSubmitted',
  'case.caseStatus',
  'clientComments',
  'injuredPartyFirstName',
  'injuredPartyMiddleName',
  'injuredPartyLastName',
  'injuredPartySuffix',
  'injuredPartyDOD',
  'injuredPartyDOB',
  'injuredPartyGender',
  'injuredPartyRelation',
  'cognitoHash',
  'addressStreet',
  'addressUnit',
  'addressCity',
  'addressState',
  'addressZIP',
  'timeZone',
  'addressManuallyVerified',
  'smartyStreetsVerified',
  'answers',
  'intakeQuestionnaireStatus',
  'cognitoForms'
]

export const opportunityDateFields = [
  'dob',
  'callbackDateTime',
  'injuredPartyDOD',
  'injuredPartyDOB',
  'timestamp',
  'dateSentClient',
  'lastContacted',
  'lastAgentUpdated',
  'updated',
  'dateSentFirm',
  'clientPortalLastAccessed',
  'forceHistory',
  'dateSigned',
  'case.caseStatusUpdated',
  'claimFormSignedDate',
  'updatedAt', // add updatedAt
  'lastAgentContacted',
  'case.claimFiledDate',
  'case.perfectedDate',
  'case.questionnaireCompletedDate',
  'retainerSignedDate',
  'otherEversignSignedDate',
  'claimFormSentDate',
  'deadline',
  'turnDownDate'
]

// Comprehensive list of all available merge fields for the opportunity, case, client and email signature
export const availableMergeFields = [
  // Opportunity merge fields
  'displayId',
  'pin',
  'source',
  'origination',
  'originatingOpp',
  'linkContext',
  'status',
  'timestamp',
  'otherDocuments',
  'contactAttemptsCount',
  'lastContacted',
  'updated',
  'updatedAt',
  'lastAgentUpdated',
  'user',
  'intakeUser',
  'managerUser',
  'attorneyUser',
  'litigationSupportUser',
  'followUp',
  'externalId',
  'externalIds',
  'reviewFlagResolver',
  'dateSentClient',
  'dateSentFirm',
  'dateSigned',
  'priority',
  'doNotCallPhone',
  'doNotTextPhone',
  'doNotCallAltPhone',
  'doNotTextAltPhone',
  'doNotEmailPrimary',
  'doNotEmailSecondary',
  'contactTime',
  'clientPortalLastAccessed',
  'gender',
  'clientPortalTokens',
  'payments',
  'unhandledEmails',
  'lastRespondent',
  'reRouteEmails',
  'rawPhoneNumber',
  'rawAlternatePhoneNumber',
  'emails',
  'cronBackfill',
  'firstRetainerSender',
  'qualifiedSignedPrice',
  'approvedESignTemplateIds',
  'activeUsers',
  'preferredLanguage',
  'partnerMetadata',
  'firstName',
  'lastName',
  'middleName',
  'suffix',
  'title',
  'preferredName',
  'phone',
  'phoneType',
  'phoneExtension',
  'addressStreet',
  'addressUnit',
  'addressStreetUnit',
  'addressCity',
  'addressState',
  'addressZIP',
  'addressCountry',
  'smartyStreetsVerified',
  'addressManuallyVerified',
  'timeZone',
  'autoTimeZone',
  'inmateId',
  'correctionalFacility',
  'dob',
  'alternatePhone',
  'alternatePhoneExtension',
  'alternatePhoneType',
  'alternateName',
  'notes',
  'publisher',
  'disqualification',
  'secondaryEmail',
  'negotiatedRetainerApproval',
  'injuredPartyDifferent',
  'injuredPartyFirstName',
  'injuredPartyMiddleName',
  'injuredPartyLastName',
  'injuredPartyDOD',
  'injuredPartyDOB',
  'injuredPartySuffix',
  'injuredPartyRelation',
  'injuredPartySSN',
  'injuredPartyGender',
  'callbackDateTime',
  'deadline',
  'originIp',
  'originURL',
  'userConsent',
  'userConsentType',
  'userConsentIds',
  // Case merge fields
  'case.casePriority',
  'case.narrative',
  'case.filedCaseNumber',
  'case.claimId',
  'case.ballotId',
  'case.pocFiledDate',
  'case.pocSignedDate',
  'case.questionnaireSentDate',
  'case.questionnaireCompletedDate',
  'case.legalAssistant',
  'case.loanAmount',
  'case.loanProvider',
  'case.answers',
  'case.checkList',
  'case.estimatedValue',
  'case.attorneyFeePercent',
  'case.attorneyFeeSplit',
  'case.finalSettlement',
  'case.caseComments',
  'case.caseStatus',
  // Email signature merge fields
  'userFirstName',
  'userLastName',
  'userJobTitle',
  'replyEmailAddress',
  'emailSignature',
  'disqualification',
  'consecutiveUnresponsiveCalls',
  'turnDownDate'
]

// api endpoints
export const apiEndpoints = [
  '/api/createOpp',
  '/api/updateOpp',
  '/api/getOppDocUploadURL',
  '/api/rest/billing',
  '/api/rest/opportunities'
]

// integration endpoints
export const integrationEndpoints = [
  '/api/integrations/askLLPAfff',
  '/api/integrations/askLLPCampLejeune',
  '/api/integrations/askLLPHairRelaxer',
  '/api/integrations/askNYWomensDetention',
  '/api/integrations/babinSA',
  '/api/integrations/carefulConsultingERC',
  '/api/integrations/galindoWagstaffCampLejeune',
  '/api/integrations/hodgeBellCampLejeune',
  '/api/integrations/ignitionPointLeadspedia',
  '/api/integrations/kellerMorseCampLejeune',
  '/api/integrations/lernerRowe',
  '/api/integrations/mauroArcherCampLejeune',
  '/api/integrations/milbergSalesForce',
  '/api/integrations/moodyBellCampLejeune',
  '/api/integrations/quinnEmanuelCampLejeune',
  '/api/integrations/saddleRockETech',
  '/api/integrations/saddleRockLR',
  '/api/integrations/smartAdvocate',
  '/api/integrations/stanleyCampLejeuneAVA',
  '/api/integrations/stanleyCampLejeuneCLM',
  '/api/integrations/tortExpertsPulaskiLawRuler',
  '/api/integrations/tritenLR',
  '/api/integrations/velawcityAfff2',
  '/api/integrations/velawcityAfffNIB',
  '/api/integrations/velawcityMaclarenHall',
  '/api/integrations/velawcityRoundupNIB',
  '/api/integrations/wattsBiltmoreTopamax',
  '/api/integrations/weitzLuxCampLejeune',
  '/api/integrations/wgApapBridgeLegal',
  '/api/integrations/zohoResponseDocuments',
  '/api/integratins/johnsonNIB'
]

// partner endpoints
export const partnerEndpoints = [
  '/api/rest/partners/partnerSlug/getPartnerFiles',
  '/api/rest/partners/partnerSlug/updatePartnerFiles',
  '/api/rest/partners/partnerSlug/deletePartnerFiles'
]

// list of fields to be selected to be verified when walking client through case questionnaire
export const caseQuestionnaireFieldsToVerify = [
  'title',
  'firstName',
  'middleName',
  'lastName',
  'suffix',
  'preferredName',
  'phone',
  'phoneType',
  'phoneExtension',
  'alternatePhone',
  'alternatePhoneType',
  'alternatePhoneExtension',
  'dob',
  'email',
  'secondaryEmail',
  'addressStreet',
  'addressUnit',
  'addressCity',
  'addressState',
  'addressZIP',
  'addressCountry',
  'gender',
  'injuredPartyDifferent',
  'injuredPartyFirstName',
  'injuredPartyMiddleName',
  'injuredPartyLastName',
  'injuredPartySuffix',
  'injuredPartyDOD',
  'injuredPartyDOB',
  'injuredPartyGender',
  'injuredPartyRelation',
  'injuredPartySSN',
  'correctionalFacility',
  'inmateId'
]

// https://www.sitepoint.com/mime-types-complete-list/
export const mimeTypes = [
  'application/gzip',
  'application/msword',
  'application/pdf',
  'application/rtf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/x-compressed',
  'application/x-zip-compressed',
  'application/zip',
  'audio/aiff',
  'audio/m4a',
  'audio/mp4',
  'audio/mpeg',
  'audio/wav',
  'audio/x-aiff',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'multipart/x-zip',
  'text/markdown',
  'text/csv',
  'text/html',
  'text/plain',
  'video/mp4',
  'video/mpeg',
  'video/quicktime',
  'video/x-ms-wmv'
]

// User notification priority levels
export const notificationPriorityTitles = {
  1: 'High Priority Notification',
  2: 'Medium Priority Notification',
  3: 'Low Priority Notification',
  4: 'Notification'
}

export const availableRelations = ['Spouse', 'Parent', 'Grandparent', 'Child', 'Grandchild', 'Other']

export const flagColorOptions = {
  red: {
    value: '252, 73, 97',
    textColor: 'black'
  },
  yellow: {
    value: '252, 227, 73',
    textColor: 'black'
  },
  green: {
    value: '97, 252, 73',
    textColor: 'black'
  },
  blue: {
    value: '73, 97, 252',
    textColor: 'white'
  },
  purple: {
    value: '228, 73, 252',
    textColor: 'black'
  },
  black: {
    value: '5, 5, 5',
    textColor: 'white'
  }
}

// For client info conditional logic, the fieldType is the field value type
// You can add an 'options' array to the object to overwrite the default options for the field
export const opportunityCoreContactFieldsWithTypes = [
  { slug: 'firstName', label: 'First Name', fieldType: 'Text Field' },
  { slug: 'middleName', label: 'Middle Name', fieldType: 'Text Field' },
  { slug: 'lastName', label: 'Last Name', fieldType: 'Text Field' },
  { slug: 'email', label: 'Email', fieldType: 'Text Field' },
  { slug: 'phone', label: 'Phone', fieldType: 'Text Field' },
  { slug: 'phoneType', label: 'Phone Type', fieldType: 'Text Field' },
  { slug: 'phoneExtension', label: 'Phone Extension', fieldType: 'Text Field' },
  { slug: 'addressStreet', label: 'Address Street', fieldType: 'Text Field' },
  { slug: 'addressUnit', label: 'Address Unit', fieldType: 'Text Field' },
  { slug: 'addressCity', label: 'Address City', fieldType: 'Text Field' },
  { slug: 'addressState', label: 'Address State', fieldType: 'Text Field' },
  { slug: 'addressZIP', label: 'Address Zip', fieldType: 'Text Field' },
  { slug: 'addressCounty', label: 'Address Country', fieldType: 'Text Field' },
  { slug: 'smartyStreetsVerified', label: 'Address Verified', fieldType: 'Boolean' },
  { slug: 'addressManuallyVerified', label: 'Address Manually Verified', fieldType: 'Boolean' },
  { slug: 'timeZone', label: 'Time Zone', fieldType: 'Text Field' },
  { slug: 'autoTimeZone', label: 'Auto Time Zone', fieldType: 'Boolean' },
  { slug: 'incarcerated', label: 'Incarcerated', fieldType: 'Boolean' },
  { slug: 'inmateId', label: 'Inmate Id', fieldType: 'Text Field' },
  { slug: 'correctionalFacility', label: 'Correctional Facility', fieldType: 'Text Field' },
  { slug: 'dob', label: 'Date Of Birth', fieldType: 'Date' },
  { slug: 'alternatePhone', label: 'Alternate Phone', fieldType: 'Text Field' },
  { slug: 'alternatePhoneExtension', label: 'Alternate Phone Extension', fieldType: 'Text Field' },
  { slug: 'alternatePhoneType', label: 'Alternate Phone Type', fieldType: 'Text Field' },
  { slug: 'alternateName', label: 'Alternate Name', fieldType: 'Text Field' },
  { slug: 'notes', label: 'Notes', fieldType: 'Text Field' },
  { slug: 'title', label: 'Title', fieldType: 'Text Field' },
  { slug: 'suffix', label: 'Suffix', fieldType: 'Text Field' },
  { slug: 'preferredName', label: 'Preferred Name', fieldType: 'Text Field' },
  { slug: 'publisher', label: 'Publisher', fieldType: 'Text Field' },
  { slug: 'secondaryEmail', label: 'Secondary Email', fieldType: 'Text Field' },
  { slug: 'negotiatedRetainerApproval', label: 'Negotiated Retainer Approval', fieldType: 'Boolean' },
  { slug: 'injuredPartyDifferent', label: 'Injured Party Different', fieldType: 'Boolean' },
  { slug: 'injuredPartyFirstName', label: 'Injured Party First Name', fieldType: 'Text Field' },
  { slug: 'injuredPartyMiddleName', label: 'Injured Party Middle Name', fieldType: 'Text Field' },
  { slug: 'injuredPartyLastName', label: 'Injured Party Last Name', fieldType: 'Text Field' },
  {
    slug: 'injuredPartyDeceased',
    label: 'Injured Party Deceased',
    fieldType: 'Boolean',
    options: [
      { value: 'Yes', label: 'Yes' },
      { value: 'No', label: 'No' }
    ]
  },
  { slug: 'injuredPartyDOD', label: 'Injured Party Date of Death', fieldType: 'Date' },
  { slug: 'injuredPartyDOB', label: 'Injured Party Date of Birth', fieldType: 'Date' },
  { slug: 'injuredPartySuffix', label: 'Injured Party Suffix', fieldType: 'Text Field' },
  { slug: 'injuredPartyRelation', label: 'Injured Party Relation', fieldType: 'Text Field' },
  { slug: 'injuredPartySSN', label: 'Injured Party SSN', fieldType: 'Text Field' },
  { slug: 'injuredPartyGender', label: 'Injured Party Gender', fieldType: 'Text Field' },
  { slug: 'callbackDateTime', label: 'Callback Date Time', fieldType: 'Date' },
  { slug: 'deadline', label: 'Deadline', fieldType: 'Date' },
  { slug: 'gender', label: 'Gender', fieldType: 'Text Field' },
  { slug: 'originIp', label: 'Origin IP', fieldType: 'Text Field' },
  { slug: 'originURL', label: 'Origin URL', fieldType: 'Text Field' },
  { slug: 'userConsent', label: 'User Consent', fieldType: 'Boolean' },
  { slug: 'userConsentType', label: 'User Consent Type', fieldType: 'Text Field' },
  { slug: 'userConsentIds', label: 'User Consent Ids', fieldType: 'Text Field' },
  { slug: 'turnDownDate', label: 'Turn Down Date', fieldType: 'Date' }
]

// A general list of markdown patterns to be used for determining if a string is markdown
// NOTE: this is a guess and may not be 100% accurate
export const markdownPatterns = [
  /^(#{1,6})\s+(.+)$/gm, // Headers
  /(?<!\w)(\*(.+?)\*|_(.+?)_)(?!\w)/g, // Italics (not surrounded by word characters)
  /\*\*(.+?)\*\*|__(.+?)__/g, // Bold
  /~~(.+?)~~/g, // Strikethrough
  /`(.+?)`/g, // Inline code
  /^\s*[-+*]\s+(.+)$/gm, // Unordered list
  /^\s*\d+\.\s+(.+)$/gm, // Ordered list
  /^>\s*(.+)$/gm, // Blockquote
  /^```(?:\s*(\w+))?([\s\S]*?)^```$/g, // Code block
  /\[(.*?)\]\((.*?)\s?(?:"(.*?)")?\)/g, // Links
  /!\[(.*?)\]\((.*?)\s?(?:"(.*?)")?\)/g // Images
]

// mock data for import rows
export const oppImportExampleData: UserFacingOppFieldNamesImportDict[] = [
  {
    displayId: 'TEST-0001',
    source: 'AVA',
    origination: 'API',
    originatingOpp: 'AVA-0001',
    status: 'sst_q_pending',
    timestamp: '2022-11-23T21:58:04.276Z',
    firstName: 'John',
    middleName: 'Felix',
    lastName: 'Doe',
    alternateName: 'Dak',
    suffix: 'Jr',
    publisher: 'Postman Test Api Token',
    email: 'johndoe@test.com',
    phone: '111-111-1111',
    phoneExtension: '1',
    phoneType: 'Cell',
    alternatePhone: '222-222-2222',
    alternateExtension: '0',
    alternatePhoneType: 'Home',
    addressStreet: '123 Test St',
    addressUnit: 'Apt 1',
    addressCity: 'Billings',
    addressState: 'MT',
    addressZIP: '59102',
    addressCountry: 'US',
    timeZone: 'Mountain',
    autoTimeZone: 'true',
    inmateId: '123456',
    preferredLanguage: 'English',
    correctionalFacility: 'Folsom',
    dob: '2022-11-23T21:58:04.276Z',
    notes: 'Here is some notes',
    comments: 'the first comment;the second comment',
    caseComments: 'the first case comment;the second case comment',
    doNotMail: 'false',
    doNotCallPhone: 'false',
    doNotTextPhone: 'true',
    doNotCallAltPhone: 'true',
    doNotTextAltPhone: 'false',
    doNotEmailPrimary: 'false',
    doNotEmailSecondary: 'true',
    narrative: '0389b58f-627f-4fd9f-a698-bb6dfde',
    externalId: '123456',
    caseStatus: 'onboarding',
    isCase: 'true',
    caseStatusUpdated: '2022-11-23T21:58:04.276Z',
    negotiatedRetainerApproval: 'false',
    injuredPartyDifferent: 'true',
    injuredPartyFirstName: 'Jane',
    injuredPartyMiddleName: 'Mary',
    injuredPartyLastName: 'Doe',
    injuredPartyDOD: '2022-11-23T21:58:04.276Z',
    injuredPartyDOB: '1999-11-23T21:58:04.276Z',
    injuredPartySSN: '123-45-6789',
    injuredPartyGender: 'Female',
    injuredPartyRelation: 'Other',
    gender: 'Male',
    flags: 'TEST_FLAG,OPP_FLAG,',
    flagIds: 'TEST_FLAG,OPP-TEST',
    priority: '1',
    intakeUser: 'google-apps|tester@reciprocityindustries.com',
    managerUser: 'google-apps|manager@reciprocityindustries.com',
    paralegalUser: 'google-apps|paralegal@reciprocityindustries.com',
    legalAssistant: 'google-apps|legal@reciprocityindustries.com',
    litigationSupportUser: 'google-apps|litigation@reciprocityindustries.com',
    reviewUser: 'google-apps|review@reciprocityindustries.com',
    deadline: '2022-11-23T21:58:04.276Z',
    round: '507dsf6-5f66-4f87-8baf-8d5g55f0a617',
    originIp: '11.11.111.111',
    userConsent: 'true',
    userConsentType: 'Marketing Firm A',
    userConsentIds: '{"user_consent_type_id":"consent id"}',
    reviewFlagResolver: 'google-apps|reviewUser@reciprocityindustries.com',
    filedCaseNumber: '223344',
    claimId: '12345',
    batchNumber: '222222',
    caseFlagIds: 'TEST_CASE_FLAG,CASE-TEST,',
    perfectedDate: '2022-11-23T21:58:04.276Z',
    questionnaireCompletedDate: '2022-11-23T21:58:04.276Z',
    claimFiledDate: '2022-11-23T21:58:04.276Z',
    clientSupportSpecialistUser: 'google-apps|clientSupport@reciprocityindustries.com',
    pcDeclinedReason: 'Signed with another firm'
  }
]

// mock data for campaign questions import rows
export const questionsImportExampleData = [
  {
    context: 'intake',
    questionText: 'Where did this happen?',
    questionSlug: 'where_did_happen',
    answerType: 'Text Field',
    instructionalText: 'Please provide the location',
    characterLimit: '100',
    min: '5',
    max: '1000',
    qualifiedOptions: '',
    requiredQuestion: 'true',
    answerOptions: '',
    group: 'Group 1',
    order: '1',
    conditionalNotMetOption: 'readOnly',
    readOnly: 'false',
    viewableBy: 'admin,superAdmin',
    editableBy: 'userGroup1,userGroup2',
    showInClientPortal: 'TRUE',
    // intake
    qualifyingQuestion: '1'
  },
  {
    context: 'intake',
    questionText: 'How many times did happen?',
    questionSlug: 'how_many_times',
    answerType: 'Select One',
    instructionalText: 'Please provide the number of times',
    characterLimit: '',
    min: '',
    max: '',
    qualifiedOptions: '1,4',
    requiredQuestion: 'FALSE',
    answerOptions: '1,2,3,4',
    group: 'Group 1',
    order: '2',
    conditionalNotMetOption: 'hide',
    readOnly: 'false',
    viewableBy: 'admin,superAdmin',
    editableBy: 'userGroup1,userGroup2',
    showInClientPortal: '0',
    // intake
    qualifyingQuestion: 'true'
  },
  {
    context: 'case',
    questionText: 'Is this a case?',
    questionSlug: 'is_case',
    answerType: 'Boolean',
    instructionalText: 'Here is some instructional text',
    characterLimit: '',
    min: '1',
    max: '50',
    qualifiedOptions: 'false',
    requiredQuestion: 'no',
    answerOptions: '1,2,3,4',
    group: 'Group 3',
    order: '1',
    conditionalNotMetOption: '',
    readOnly: '1',
    viewableBy: 'admin,superAdmin',
    editableBy: 'userGroup1,userGroup2',
    showInClientPortal: 'true',
    // case
    showInCaseQuestionnaire: 'yes',
    pocFieldName: 'POC Field Name'
  }
]

// sample data for opportunity task CSV
export const opportunityTaskImportExampleData = [
  {
    type: 'opportunity',
    taskLabel: 'Create Opportunity',
    description: 'Create opportunity for new lead from AVA',
    group: '',
    deadline: '2025-03-15',
    completed: 'no'
  },
  {
    type: 'opportunity',
    taskLabel: 'Update Opportunity',
    description: 'Update opportunity for new lead from AVA',
    group: '',
    deadline: '2025-03-16',
    completed: 'no'
  }
]

// sample data for medRecordRequest task CSV
export const medRecordRequestTaskImportExampleData = [
  {
    type: 'medRecordRequest',
    taskLabel: 'Find medical records',
    description: 'Find medical records for new lead from AVA',
    status: 'atty_med_rec_req_in_progress',
    group: '',
    deadline: '2025-03-15',
    completed: 'no'
  },
  {
    type: 'medRecordRequest',
    taskLabel: 'Delete medical records',
    description: 'Delete medical records for new lead from AVA since they are not needed',
    status: 'atty_med_rec_req_in_progress',
    group: '',
    deadline: '2025-03-16',
    completed: 'no'
  }
]

module.exports = {
  answerTypes,
  campaignFields,
  caseSortFieldKeys,
  defaultPhoneTypes,
  defaultSuffixes,
  defaultTitles,
  defaultClientTypes,
  genders,
  fieldsWithTimestampValues,
  ignoreDST,
  ignoredDupeCheckerNames,
  neverNeedToBeMergeFields,
  opportunityCoreContactFields,
  opportunityWebhookPayloadFields,
  oppSideBarLabels,
  oppTabLabels,
  emailSignatureMergeFields,
  restrictedOppStatuses,
  restrictedCaseStatuses,
  states,
  timeZones,
  userActionsDictionary,
  userFacingClientInfoFieldNamesDict,
  userFacingCaseFieldNamesDict,
  userFacingOppFieldNamesDict,
  userFacingOppFieldNamesImportDict,
  userFacingCampaignFieldNamesDict,
  dateRangeFields,
  smsHumanReadable,
  opportunityOriginations,
  allOrderedColumns,
  phoneAreaCodes,
  fieldIndexOrder,
  allowedGridStringOperators,
  allowedGridDateOperators,
  conditionalOperators,
  conditionalOperatorValueTypes,
  validationRegex,
  roleNamesInDB,
  atlasSortableFields,
  clientPortalFields,
  languages,
  disqualifiedOppStatuses,
  opportunityDateFields,
  availableMergeFields,
  booleanFields,
  caseHiddenColumns,
  apiEndpoints,
  integrationEndpoints,
  partnerEndpoints,
  caseQuestionnaireFieldsToVerify,
  notificationPriorityTitles,
  mimeTypes,
  availableRelations,
  flagColorOptions,
  opportunityCoreContactFieldsWithTypes,
  markdownPatterns,
  answerFormats,
  oppImportExampleData,
  questionsImportExampleData,
  opportunityTaskImportExampleData,
  medRecordRequestTaskImportExampleData
}
